import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoStar } from "react-icons/io5";
import { IoStarHalf } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { SingleItem } from "./SingleItem";

export function SearchPage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const params = useParams();

  const [shoWProducts, setShowProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [temploading, setTempLoading] = useState(true);

  useEffect(() => {
    const searchKey = params.keyword;
    window.scroll(0,0)
    const fetchSearchProducts = async () => {
      // console.log(searchKey);
      const url = `${Baseurl}/api/v1/product/productsearchbykeywords/${searchKey}`;
      const getProducts = await axios.get(url);
      try {
        if (getProducts.data.success) {
          const products = getProducts.data.products;
          setTempLoading(false);
          setShowProducts(products);
          setProducts(products);
          // console.log(products);
        }
      } catch (error) {}
    };

    fetchSearchProducts();
  }, [params.keyword]);

  return (
    <>
      <div className="p50">
        <div className="search-title">Search Results for {params.keyword}</div>
        <div className="search-filters">
          <div className="search-filters-leftpanel">
            SHOWING {shoWProducts.length} OF {shoWProducts.length} RESULTS
          </div>
          <div>
          </div>
        </div>
        <div className="row">
          {shoWProducts.map((data, index) => {
            return <SingleItem key={index} data={data}/>
          }
            
          )}
        </div>
      </div>
    </>
  );
}
