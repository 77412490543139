import { useState } from "react";
import {
  clientRegistation,
  authActions,
} from "../../../redux/athentication/Athentication";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoPersonCircleOutline } from "react-icons/io5";
import SecondHeader from "../../SecondHeader";

export function SignIn() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [errormessageEmail, setErrormessageEmail] = useState("");
  const [uniquemail, setUniqueEmail] = useState("");

  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfpassword] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const nameChange = (e) => {
    let value = e.target.value;
    setName(value);
  }
  const mobileChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    setMobile(value);
  }
  const emailChange = (e) => {
    const value = e.target.value;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmail(value);
    setErrormessageEmail("");
    if (value.length > 0) {
      if (value.match(mailformat)) {
        if (value != "") {
          setUniqueEmail(value);
        }
      } else {
        setErrormessageEmail("please enter correct Email format");
      }
    } else {
      setErrormessageEmail("please enter correct Email format");
    }
  }
  const passwordChange = (e) => {
    let value = e.target.value;
    setPassword(value);
  }
  const cnfPasswordChange = (e) => {
    let value = e.target.value;
    setCnfpassword(value);
  }

  const handleRegdSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (
      name !== "" &&
      email !== "" &&
      mobile !== "" &&
      mobile.length === 10 &&
      password !== "" &&
      cnfPassword !== "" &&
      password === cnfPassword
    ) {
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        email: email,
        mobile: mobile,
        password: password,
      };
      console.log(formData, "formData");
      const userDetails = await dispatch(clientRegistation(formData));
      console.log(userDetails, "userDetails");
      if (userDetails.payload.success === true) {
        const userData = userDetails.payload.client;
        const authenticForm = {
          ...userData,
          isAuth: true,
        };
        dispatch(authActions.signIn(authenticForm));
        setBtnLoading(false);
        navigate("/");
      }
    } else if (name === "") {
      alert("Name field can not be blank.")
      toast.error("Name field can not be blank.", {
        position: "top-center",
        autoClose: 1000,
      });
    } else if (email === "") {
      alert("Email field can not be blank.")
      toast.error("Email field can not be blank.", {
        position: "top-center",
        autoClose: 1000,
      });
    } else if (mobile === "" || mobile.length !== 10) {
      toast.error("Mobile number should be exactly 10 numeric characters.", {
        position: "top-right",
        autoClose: 1000,
      });
    } else if (password !== cnfPassword) {
      toast.error("Password doesn't match", {
        position: "top-right",
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <SecondHeader />
      <div>
        <div className="pt-4 login">
          <div className="login-inner">
            <div className="icon-box">
              <IoPersonCircleOutline className='register-icon' />
            </div>
            <form id="login-form" onSubmit={(e) => handleRegdSubmit(e)}>
              <div className="container">
                <label htmlFor="uname">
                  <div className="register-label">Name</div>
                </label>
                <input
                  type="text"
                  className="register-input"
                  placeholder="Enter Name"
                  name="uname"
                  required
                  value={name}
                  onChange={nameChange}
                />
                {/* <label htmlFor="lastname">
                  <b>Last name</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Last name"
                  name="lastname"
                  required
                  value={lastName}
                  onChange={lastNameChange}
                /> */}
                <label htmlFor="mobile">
                  <div className="register-label">Mobile no.</div>
                </label>
                <input
                  type="text"
                  className="register-input"
                  placeholder="Enter Mobile"
                  name="mobile"
                  required
                  value={mobile}
                  onChange={mobileChange}
                />
                <label htmlFor="email">
                  <div className="register-label">Email</div>
                </label>
                <input
                  type="text"
                  placeholder="Enter Email"
                  className="register-input"
                  name="email"
                  required
                  value={email}
                  onChange={emailChange}
                />
                <p style={{ color: "red" }} className="mt-2">
                  {errormessageEmail}
                </p>
                <label htmlFor="psw">
                  <div className="register-label">Password</div>
                </label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  className="register-input"
                  name="psw"
                  required
                  minLength={8}
                  value={password}
                  onChange={passwordChange}
                />
                <label htmlFor="psw">
                  <div className="register-label">Confirm Password</div>
                </label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  className="register-input"
                  name="psw"
                  required
                  minLength={8}
                  value={cnfPassword}
                  onChange={cnfPasswordChange}
                />
                <button type="submit" className="regsiter-btn">Signin</button>
                <div className="register-now">---Already Have An Account---</div>
                <button type="submit" className="regsiter-btn " onClick={() => navigate("/login")}>Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
