import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "react-toastify/dist/ReactToastify.css";
import {
  authActions,
  validateEmail,
} from "../../redux/athentication/Athentication";
import { addAddress, addressSetup, addressActions,modalShow} from "../../redux/address/Address";


export function Addaddress() {
  const { isAuth, loginData } = useSelector((state) => state.Athentication);
  const { addresses } = useSelector((state) => state.Address)
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [number, setNumber] = useState("");
  const [Errormessage, setErrormessage] = useState("");
  const [addressType, setAddressType] = useState("Home");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodecon, setPinCodecon] = useState("");
  const [pinErrormessage, setPinErrormessage] = useState("");
  const [addressState, setAddressState] = useState([])

  const [mainErrormessage, setMainErrormessage] = useState("");


  const dispatch = useDispatch();

  // console.log(addressState);
  const changePincode = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setPinErrormessage("");
    setPincode(numbers);
    if (numbers.length === 6) {
      setPinCodecon(numbers);
      setPinErrormessage("");
    } else {
      setPinCodecon("");
      setPinErrormessage("please enter currect pincode");
    }
  };

  const handleChange = (e) => {
    // dispatch(modalShow(false))
    const numbers = e.target.value.replace(/\D/g, "");
    setErrormessage("");
    setNumber(numbers);
    if (numbers.length === 10) {
      setMobile(numbers);
      setErrormessage("");
    } else {
      setMobile("");
      setErrormessage("please enter currect number");
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    dispatch(addressActions.modalShow(false))
    const Name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    setMainErrormessage("");
    if (mobile !== "" && pincodecon !== "" && city !== "") {
      try {
        const formData = {
          name: Name,
          ClientId: loginData._id,
          phn: mobile,
          altPhn: loginData.mobile,
          address: address,
          city: city,
          state: state,
          pincode: pincodecon,
          type: addressType
        };
        console.log(formData);
        const setAddress = await dispatch(addressSetup(formData));
        if (setAddress.payload.success === true) {
          const currAddress = setAddress.payload.address;
          dispatch(addressActions.updateAddress(currAddress))
        }


      } catch (error) { }
    } else {
      toast.error("please enter currect values", {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <div className="col-md-12 col-lg-12 col-sm-12 p-25  add-address ">
        <div className="add-address-inner m-auto">
          <div className="mb-25 d-flex justify-content-between ">
            <h4>Add Address</h4>
            <button
              style={{ cursor: "pointer",backgroundColor:'unset',border:'none',outline:'none' }}
            onClick={()=>dispatch(modalShow(false))}
            >
              X
            </button>
          </div>
          <form>
            <div className="form-group">
              <input
                type="text"
                required
                name="fname"
                placeholder="Full name *"
                className="form-control "
                style={{ textTransform: "capitalize" }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                required
                name="mobile no"
                placeholder="Mobile Number*"
                className="form-control"
                maxLength={10}
                minLength={10}
                style={{ textTransform: "capitalize" }}
                value={number}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <p style={{ color: "red" }}>{Errormessage}</p>
            </div>
            <div className="form-group">
              <input
                type="text"
                required
                name="mobile no"
                placeholder="Alt Mobile Number*"
                className="form-control"
                value={loginData.mobile}
                readOnly
                // onChange={(e) => setAltMobile(e.target.value)}
                style={{ textTransform: "capitalize" }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                required
                name="email"
                placeholder="Email*"
                className="form-control"
                style={{ textTransform: "capitalize" }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="Address"
                required
                placeholder="Address *"
                className="form-control"
                style={{ textTransform: "capitalize" }}
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                required
                type="text"
                name="city"
                placeholder="Enter City *"
                className="form-control"
                style={{ textTransform: "capitalize" }}
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                required
                type="text"
                name="state"
                placeholder="State *"
                className="form-control"
                style={{ textTransform: "capitalize" }}
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                required
                type="text"
                name="pincode"
                placeholder="Enter Pin Code"
                maxLength={6}
                minLength={6}
                className="form-control"
                style={{ textTransform: "capitalize" }}
                value={pincode}
                onChange={(e) => {
                  changePincode(e);
                }}
              />
              <p style={{ color: "red" }}>{pinErrormessage}</p>
            </div>

            <div className="designAdrs">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  //   className="form-control"
                  value="Home"
                  checked={addressType === "Home"}
                  onChange={(e) => {
                    setAddressType("Home");
                  }}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Home
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="Office"
                  checked={addressType === "Office"}
                  onChange={(e) => {
                    setAddressType("Office");
                  }}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Office
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="Others"
                  checked={addressType === "Others"}
                  onChange={(e) => {
                    setAddressType("Others");
                  }}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault3">
                  Others
                </label>
              </div>
            </div>
            <p style={{ color: "red" }}>{mainErrormessage}</p>
            <div className="modal-footer">
              <button
                type="button"
                className="btn bg-danger text-light btn-md"
              onClick={()=>dispatch(modalShow(false))}
              >
                Close
              </button>
              <button
                type="button"
                className="btn bg-success text-light ms-1 btn-md"
                onClick={(e) => onSubmitHandler(e)}
              >
                Save Address
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
