import React, { useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

function CompanyOffers() {
    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return (
        <>
            <div className="company-offers">
                <div className="company-offers-heading">Courier from India to the USA</div>
                <p className='company-offers-text'>Vasant Couriers offers reliable and fast domestic and international courier delivery services at affordable rates. With us, you can send important documents, medicines, extra luggage, etc. We are one of the prominent courier companies offering an array of courier shipping services covering Telangana, Hyderabad and other significant locations across the country. We are renowned for fast, on time and reliable deliveries of your consignments. Therefore you can trust us, we even offer packing and pickup services in Bangalore, Hyderabad, Chennai and some other locations in Telangana and AP. We have active customer support; whenever you have any doubt in your mind, you will have professional guidance by your side. We have experienced teams and authentic networks, that's why there is hardly any report of missing package till the time. Book Courier from India to the USA with us.</p>
                <div className='company-offers-heading'>Worldwide Shipping Company in India</div>
                <div className='accordion-box'>
                    <Accordion flush open={open} toggle={toggle}>
                        <AccordionItem>
                            <AccordionHeader targetId="1">Are our Worldwide Shipping Companies in india safe?</AccordionHeader>
                            <AccordionBody accordionId="1">
                                We can't speak for others, but we at vasant courier are responsible for the timely, cost effective, and secure delivery of your courier
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="2">How long does international shipping from India take to deliver my parcel?</AccordionHeader>
                            <AccordionBody accordionId="2">
                                Safely transporting your items to your selected location takes 4-6 business days.
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="3">What makes Vasant Courier the best courier service in India?</AccordionHeader>
                            <AccordionBody accordionId="3">
                            Vasant Courier has great people and customer service qualities. We guarantee on-time delivery and a decent degree of friendliness, and a thorough understanding of payment and service methods.
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="4">Can anyone suggest the best Affordable Courier Service from India to the USA?</AccordionHeader>
                            <AccordionBody accordionId="4">
                                Of course, Vasant Courier due to its reliability. It offers one of the faster parcel delivery services.
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="5">Is Vasant Courier is the only Shipping Services from India to the UK?</AccordionHeader>
                            <AccordionBody accordionId="5">
                                There are other firms, but we are the best because of our performance.
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </>
    )
}

export default CompanyOffers