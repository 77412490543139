import React from 'react'
import OurServices from '../Home/OurServices'
import BusinessStrategy from '../Home/BusinessStrategy'
import BusinessFeatures from '../Home/BusinessFeatures'
import CompanyOffers from '../Home/CompanyOffers'
import PastCourierOrders from '../Home/PastCourierOrders'
import SlideBanner from '../Home/SlideBanner'
import ShopNow from '../Home/ShopNow'
import TrackId from '../Home/TrackId'

function CourierHomePage() {
  return (
    <>
      <SlideBanner />
      {/* <TrackId/> */}
      <ShopNow />
      <OurServices />
      <BusinessStrategy />
      <PastCourierOrders />
      <BusinessFeatures />
      <CompanyOffers />
      <div className='whatsapp-logo'>
        <a href="//api.whatsapp.com/send?phone=919391445005" className="ps-3">
          <img src="/asset/images/banner/4017334_circle_logo_media_network_social_icon.png" alt="whatsapp logo" className='whatsapp-img' />
        </a>
      </div>
    </>
  )
}

export default CourierHomePage