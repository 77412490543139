import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addtoCart } from "../redux/cart/CartSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { SingleItem } from "./SingleItem";

export function OfferProducts() {
  const { offersTotal } = useSelector((store) => store.Product);

  return (
    <>
      <div className="p100 trending">
        <div className="trending-heading">
          <div className="title">Offer Products</div>
          <div className="sm-hr">
            <hr />
          </div>
        </div>
        <div className="trending-subheading">
          Top Offer products in this week
        </div>
        <div className="row">
          {offersTotal.map((data, index) => 
           {
            return <SingleItem data={data} key={index}/>
           }
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
