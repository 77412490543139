import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import SecondHeader from '../../components/SecondHeader'
import { ToastContainer, toast } from "react-toastify";

const CourierPrice = () => {
    const { courierPriceList } = useSelector((store) => store.CourierPrice);
    const [country, setCountry] = useState("");
    const [weight, setWeight] = useState("");
    const [type, setType] = useState("");
    const [price, setPrice] = useState("")

    const handleCountry = (e) => {
        const value = e.target.value.trim()
        setCountry(value.toUpperCase())
    }
    const handleWeight = (e) => {
        let value = e.target.value.trim();

        if (!value.includes('.')) {
            value += '.0';
        }
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        setWeight(value);
    }
    const handlePrice =async (e) => {
        e.preventDefault()
        let foundPrice = false;
        if (type === "DX" && weight > "2") {
            toast.error("We don't deliver DX type of order above 2Kg.", {
                position: "top-center",
                autoClose: 1000,
            });
            setCountry("");
            setType("");
            setWeight("")
        } else {
            await new Promise((resolve, reject) => {
                for (var i = 0; i < courierPriceList.length; i++) {
                    if (country === courierPriceList[i].country && weight === courierPriceList[i].weight && type === courierPriceList[i].couriersName) {
                        setPrice(courierPriceList[i].price);
                        foundPrice = true;
                        setCountry("");
                        setType("");
                        setWeight("");
                        resolve(); // Resolve the Promise once the price is found
                        break;
                    }
                }
                resolve(); // Resolve the Promise even if the price is not found
            })
            if (!foundPrice) {
                toast.error("Price not available for the given country/weight combination.", {
                    position: "top-center",
                    autoClose: 1000,
                });
                setCountry("");
                setType("");
                setWeight("")
                setPrice("")
            }
        }

    }
    return (
        <>

            {
                courierPriceList ? (<>
                    <SecondHeader />
                    <div className='courier-price-top'>
                        <div className='form-background row'>
                            <div className="courier-enquiry-form col-lg-6">
                                <div className='courier-enquiry-form-inner'>
                                    <div className='package-title'>Please refer to the following to get our current prices to ship from India.
                                    </div>
                                    {/* <div>Currently we are shipping to USA and Canada only</div> */}
                                    <div className="wrapper">
                                        <form className="form-signin" onSubmit={(e) => handlePrice(e)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <h4 className="form-signin-heading">Submit Your Info</h4>
                                            <input type="text" className="form-control" name="country" checked placeholder="Enter country name" required autofocus value={country} onChange={(e) => handleCountry(e)} />
                                            <input type="text" className="form-control" name="weight" placeholder="Enter courier weight" required value={weight} onChange={(e) => setWeight(e.target.weigth)} onBlur={(e) => handleWeight(e)} />
                                            <div className='mt-3'>Courier Type:</div>
                                            <div style={{
                                                display: 'flex', alignItems: 'center', gap: '30px'
                                            }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    <input type="radio" id="html" name="fav_language" defaultValue="HTML" value={type} onChange={() => setType("DX")} />
                                                    &nbsp; <label htmlFor="html">DX</label><br />
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    &nbsp; <input type="radio" id="css" name="fav_language" defaultValue="CSS" value={type} onChange={() => setType("NDX")} />
                                                    &nbsp; <label htmlFor="css">NDX</label><br />
                                                </div>
                                            </div>
                                            <button className="enquiry-btn " style={{ margin: '5px auto' }} type="submit"  >Get Price</button>
                                            {
                                                price ? (<>
                                                    <div style={{ fontWeight: '600', color: '#555' }}>
                                                        {`Your effective price is ${price}`}
                                                    </div>
                                                </>) : (<></>)
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='do-dont-text col-lg-6'>
                                <div className='do-dont-innertext'>
                                    <div className='do-dont-title'>Some Term And Conditions</div>
                                    <div className='do-dont-tnc'>
                                        * Weights Specified - <span className='do-dont-tnc-text'> The rates quoted above are based on the weights specified. Shipping weight
                                            will be calculated as the higher of actual
                                            weight of the shipment and the volumetric weight. </span>
                                    </div>
                                    <div className='do-dont-tnc'>
                                        * Weights -
                                        <span className='do-dont-tnc-text'>* Weights will be rounded up to the nearest .5kg for shipments
                                            under 20kgs and will be rounded up to nearest 1 kg for shipments over 20 kgs.</span>
                                    </div>
                                    <div className='do-dont-tnc'>
                                        * Final charges -
                                        <span className='do-dont-tnc-text'>
                                            Final charges will depend upon the actual weights measured at the time of tendering the shipment.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<></>)
            }
            <ToastContainer />
        </>
    )
}

export default CourierPrice