import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function Category() {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const { categorytotal } = useSelector((store) => store.Category)

  const handleCategory = (item) => {
    navigate(`/productbycategory/${item.catname}`)
  }
  return (
    <>
      <div className="p100">
        <div className="category-title">
          By Category
          <div className="sm-hr">
            <hr />
          </div>
        </div>
        <div
          className="row"
        >
          {categorytotal.map((item, index) =>
            <div key={index} className="col-lg-2 col-sm-4 col-6 p-2">
              <div className="category-box">
                <img src={item.mblimg} alt="" style={{ width: '100%' }}
                  onClick={() => {
                    handleCategory(item)
                  }}
                />
                <div className="categoryname-title"></div>
              </div>
              <div
                className="bg-dark text-light p-2"
                style={{ textAlign: "center" }}
                onClick={() => {
                  handleCategory(item)
                }}
              >
                {item.catname}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
