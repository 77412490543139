import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { act } from "react-dom/test-utils";

const initialState = {
  CartItems: localStorage.getItem("CartItems")
    ? JSON.parse(localStorage.getItem("CartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  netPayable: 0,
  cartTotalPrice: 0,
  cartPackQuantity: 0,
  CouponDiscountAmount: 0,
  logisticAmount: 0,
  Cartloading: true,
};

const CartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    addtoCart(state, action) {
      let itemIndex = -1;
      itemIndex = state.CartItems.findIndex(
        (item) => item._id === action.payload._id
      );
      if (itemIndex > -1) {
        if (
          state.CartItems[itemIndex].packsize.packName !==
          action.payload.packsize.packName
        ) {
          itemIndex = -1;
        }
      }
      if (itemIndex >= 0) {
        state.cartTotalQuantity++;
        let quant = state.CartItems[itemIndex].Qty + 1;

        state.CartItems[itemIndex].Qty = state.CartItems[itemIndex].Qty + 1;

        state.CartItems[itemIndex].TotalPrice = Number(
          state.CartItems[itemIndex].packsize.price * quant
        );

        state.CartItems[itemIndex].TotalAmount = Number(
          state.CartItems[itemIndex].packsize.price * quant
        );

        state.cartTotalAmount =
          state.cartTotalAmount + action.payload.packsize.price;

        if (state.cartTotalAmount >= 500) {
          state.logisticAmount = 0;
        } else {
          state.logisticAmount = 40;
        }
        state.netPayable = state.cartTotalAmount + state.logisticAmount;
      } else {
        const tempProducts = action.payload;
        state.CartItems.push(tempProducts);

        state.cartTotalQuantity++;
        state.cartPackQuantity++;

        state.cartTotalAmount =
          state.cartTotalAmount + action.payload.packsize.price;

        if (state.cartTotalAmount >= 500) {
          state.logisticAmount = 0;
        } else {
          state.logisticAmount = 40;
        }
        state.netPayable = state.cartTotalAmount + state.logisticAmount;
      }

      state.Cartloading = !state.Cartloading;
      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
    decrementCart(state, action) {
      let itemTheIndex = -1;
      itemTheIndex = state.CartItems.findIndex(
        (item) => item.packsize.packName === action.payload.packsize.packName
      );
      if (itemTheIndex >= 0) {
        if (state.CartItems[itemTheIndex].Qty > 1) {
          let quant = state.CartItems[itemTheIndex].Qty - 1;
          state.CartItems[itemTheIndex].Qty =
            state.CartItems[itemTheIndex].Qty - 1;
          state.CartItems[itemTheIndex].TotalAmount = Number(
            state.CartItems[itemTheIndex].TotalAmount * Number(quant)
          );
          state.CartItems[itemTheIndex].TotalPrice = Number(
            state.CartItems[itemTheIndex].TotalPrice * Number(quant)
          );
        } else if (state.CartItems[itemTheIndex].Qty === 1) {
          const nextCartItems = state.CartItems.filter(
            (cartItem) =>
              cartItem.packsize.packName !== action.payload.packsize.packName ||
              cartItem._id !== action.payload._id  
          );
          state.cartPackQuantity--;
          state.CartItems = nextCartItems;
        }

        state.cartTotalAmount =
          Number(state.cartTotalAmount) - Number(action.payload.packsize.price);

        if (state.cartTotalAmount >= 500) {
          state.logisticAmount = 0;
        } else {
          state.logisticAmount = 0;
        }

        state.netPayable =
          Number(state.cartTotalAmount) + Number(state.logisticAmount);
        state.Cartloading = !state.Cartloading;
        localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
      }
    },
    removefromCart(state, action) {
      let itemInCartIndex = -1;

      itemInCartIndex = state.CartItems.findIndex(
        (item) => item.packsize.packName === action.payload.packsize.packName
      );
      if (itemInCartIndex >= 0) {
        let comparePacksizeIndex = -1;
        comparePacksizeIndex = state.CartItems.findIndex(
          (item) => item._id === action.payload._id
        );
        if (comparePacksizeIndex >= 0) {
          console.log(comparePacksizeIndex, "comparePacksizeIndex");
          const nextCartItems = state.CartItems.filter(
            (cartItem) =>
              cartItem._id !== action.payload._id ||
              cartItem.packsize.packName !== action.payload.packsize.packName
          );
          state.cartPackQuantity--;
          state.CartItems = nextCartItems;
          state.Cartloading = !state.Cartloading;
          localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
        }
      }
    },
    getCartTotal(state, action) {
      state.cartTotalAmount = 0;
      state.netPayable = 0;
      state.cartTotalMrp = 0;
      state.cartTotalPrice = 0;
      const totalitem = state.CartItems;

      for (let i = 0; i < state.CartItems.length; i++) {
        console.log(state.CartItems[i].TotalAmount);
        state.cartTotalAmount =
          state.cartTotalAmount + state.CartItems[i].TotalAmount;
        state.cartTotalPrice =
          state.cartTotalPrice + state.CartItems[i].TotalPrice;
      }

      if (state.cartTotalAmount >= 500) {
        state.logisticAmount = 0;
      } else {
        state.logisticAmount = 0;
      }
      state.netPayable = state.cartTotalAmount + state.logisticAmount;
    },

    clearCart(state, action) {
      state.CartItems = [];
      state.cartTotalQuantity = 0;
      state.cartTotalAmount = 0;
      state.cartTotalPrice = 0;
      state.netPayable = 0;
      state.cartTotalMrp = 0;
      state.cartPackQuantity = 0;
      state.Cartloading = !state.Cartloading;
      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
  },
});

export const {
  addtoCart,
  removefromCart,
  decrementCart,
  getCartTotal,
  clearCart,
} = CartSlice.actions;
export default CartSlice.reducer;
