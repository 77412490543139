import { useEffect, useState } from "react";
import { RiShoppingCart2Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import SecondHeader from "./SecondHeader";

export function OrderSuccess() {

    const { awb } = useSelector((store) => store.Order);
    const [trackingNo, setTrackingNo] = useState("")
    useEffect(() => {
        setTrackingNo(awb)
    }, [])
    return (
        <>
            <SecondHeader />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: "#e91e63", minHeight: '60vh' }} >
                <div style={{ fontSize: '130px' }}>
                    <RiShoppingCart2Line />
                </div>
                <div style={{
                    color: "black", textAlign: 'center'
                }}>
                    <h3>Thank you for your order</h3>
                    <p>
                        We will send you a shipping confirmation message on whatsapp as soon
                        <br /> as your order ships
                    </p>
                    <div>
                        Track your order:{trackingNo}
                    </div>
                    <div>
                        Expected Delivery:01-02-24
                    </div>
                </div>
            </div>
        </>
    )
}