import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  productTotal: [],

  offersTotal: [],
  trendingsTotal: [],
  dealofthedayTotal: [],
  recommendedTotal: [],

  isLoading: true,
  isproductLoading: true,
  isoffersLoading: true,
  istrendingLoading: true,
  isdealofthedayLoading: true,
  isrecommendedLoading: true,
};

export const getAllProduct = createAsyncThunk(
  "Product/getAllProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/all`;
      const resp = await axios(url);
      return resp.data.Products;
    } catch (error) {
      return thunkAPI.rejectWithValue("Product not found");
    }
  }
);
export const getAllTrendingProduct = createAsyncThunk(
  "Product/getAllTrendingProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/producttrending`;
      const resp = await axios(url);
      return resp.data.trending;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not Found");
    }
  }
);


export const getAllOfferProduct = createAsyncThunk(
  "Product/getAllOfferProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/productoffers`;
      const resp = await axios(url);
      return resp.data.offers;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not Found");
    }
  }
);
export const getAllDealoftheDayProduct = createAsyncThunk(
  "Product/getAllDealoftheDayProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/productdealoftheday`;
      const resp = await axios(url);
      return resp.data.dealofthedays;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not Found");
    }
  }
);
export const getAllRecommendedProduct = createAsyncThunk(
  "Product/getAllRecommendedProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/productrecommended`;
      const resp = await axios(url);
      return resp.data.recommended;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not Found");
    }
  }
);

const productSlice = createSlice({
  name: "ProductAll",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProduct.fulfilled, (state, action) => {
        state.productTotal = action.payload;
        state.isLoading = false;
        state.isproductLoading = false;
        localStorage.setItem(
          "productTotal",
          JSON.stringify(state.productTotal)
        );
      })
      .addCase(getAllProduct.rejected, (state) => {
        state.isLoading = true;
      })
      //offers
      .addCase(getAllOfferProduct.pending, (state) => {
        state.isoffersLoading = true;
      })
      .addCase(getAllOfferProduct.fulfilled, (state, action) => {
        state.offersTotal = action.payload;
        state.isoffersLoading = false;
      })
      .addCase(getAllOfferProduct.rejected, (state) => {
        state.isoffersLoading = true;
      })
      //trending
      .addCase(getAllTrendingProduct.pending, (state) => {
        state.istrendingLoading = true;
      })
      .addCase(getAllTrendingProduct.fulfilled, (state, action) => {
        state.trendingsTotal = action.payload;
        state.istrendingLoading = false;
      })
      .addCase(getAllTrendingProduct.rejected, (state) => {
        state.istrendingLoading = true;
      })
      //dealoftheday
      .addCase(getAllDealoftheDayProduct.pending, (state) => {
        state.isdealofthedayLoading = true;
      })
      .addCase(getAllDealoftheDayProduct.fulfilled, (state, action) => {
        state.dealofthedayTotal = action.payload;
        state.isdealofthedayLoading = false;
      })
      .addCase(getAllDealoftheDayProduct.rejected, (state) => {
        state.isdealofthedayLoading = true;
      })
      //recommended
      .addCase(getAllRecommendedProduct.pending, (state) => {
        state.isrecommendedLoading = true;
      })
      .addCase(getAllRecommendedProduct.fulfilled, (state, action) => {
        state.recommendedTotal = action.payload;
        state.isrecommendedLoading = false;
      })
      .addCase(getAllRecommendedProduct.rejected, (state) => {
        state.isrecommendedLoading = true;
      });
  },
});

export default productSlice.reducer;
