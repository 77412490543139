import React, { useEffect, useState } from 'react'
import { courierRequestPost } from "../../redux/courierRequest/courierRequestSlice";
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SecondHeader from '../../components/SecondHeader';

const CouriereEnquiry = () => {
    const params = useParams()
    const [name, setName] = useState("");
    const [mob, setMob] = useState(null)
    const [address, setAddress] = useState("")
    const [query, setQuery] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [enquiryFor, setEnquiryFor] = useState("");


    useEffect(() => {
        setName("");
        setMob("");
        setAddress("");
        setQuery("");
        window.scrollTo(0, 0)
    }, [])

    const handleNameChange = (e) => {
        const uname = e.target.value;
        if (uname.length >= 6) {
            setName(uname)
        }
        else {
            alert("name cann't be less than 6 character")
        }
    }

    const handleMobChange = (e) => {
        const umob = e.target.value;
        if (umob.length === 10 && !isNaN(umob)) {
            setMob(umob)
        } else {
            alert("Mobile number should contain exactly 10 numeric character")
        }
    }

    const handleAddressChange = (e) => {
        const uaddress = e.target.value;
        if (typeof uaddress === "string") {
            setAddress(uaddress)
        } else {
            alert("Please provide valid address")
        }
    }

    const handleQueryChange = (e) => {
        const uquery = e.target.value;
        if (typeof uquery === "string") {
            setQuery(uquery)
        } else {
            alert("Please describe your requirement for more Clarity!!!")
        }
    }

    useEffect(() => {
        let enquiry = params.enquirytype;
        if (enquiry === "foodwrapping") {
            setEnquiryFor("request pickle packing at your doorstep")
        }
        else if (enquiry === "requestPickup") {
            setEnquiryFor("request courier pickup at your doorstep")
        }
        else if (enquiry === "bubblewrapping") {
            setEnquiryFor("request bubble wrapping at your doorstep")
        }
        else if (enquiry === "suitcasewrapping") {
            setEnquiryFor("request suitcase wrapping at your doorstep")
        }
        else if (enquiry === "vaccumpacking") {
            setEnquiryFor("request vaccum packing at your doorstep")
        }
        else if (enquiry === "airbubblewrapping") {
            setEnquiryFor("request air bubble packing at your doorstep")
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (name !== "" && mob.length === 10 && address !== "" && query !== "") {
            const formData = {
                clientName: name,
                clientMobile: mob,
                clientAddress: address,
                clientQuery: query
            }
            console.log(formData, "asdfg");
            const requestCourier = await dispatch(courierRequestPost(formData))
            console.log(requestCourier.payload);
            if (requestCourier.payload.success) {
                toast.success("Courier request created successfully", {
                    position: "top-center",
                    autoClose: 500,
                });
                setName("");
                setMob("");
                setAddress("");
                setQuery("")
            }
        }
    }

    return (
        <>
            <SecondHeader />
            <div>
                <div>
                    <img src="/asset/images/courier/couriers.jpg" alt="" className='courier-enquiry-img' />
                </div>
                <div className='form-background row'>
                    <div className="courier-enquiry-form col-lg-8">
                        <div className='courier-enquiry-form-inner'>
                            <div className='package-title'>Send packages from Hyderabad to Anywhere</div>
                            <div>{enquiryFor}</div>
                            <div className="wrapper">
                                <form className="form-signin" onSubmit={(e) => handleSubmit(e)}>
                                    <h4 className="form-signin-heading">Submit Your Info</h4>
                                    <input type="text" className="form-control" name="username" placeholder="Enter Your Name" required autofocus value={name} onChange={(e) => setName(e.target.value)} />
                                    <input type="text" className="form-control" name="password" placeholder="Mobile No." required value={mob} onChange={(e) => setMob(e.target.value)} />
                                    <input type="text" className="form-control" name="address" placeholder="Your Address" required value={address} onChange={(e) => handleAddressChange(e)} />
                                    <textarea className="form-control" id="" cols="30" rows="3" placeholder='Plsease describe what kind of product you want to courier...' value={query} onChange={(e) => handleQueryChange(e)}></textarea>
                                    <label className="checkbox mt-2">
                                        <input type="checkbox" />
                                        <p className='tNc-text ps-2'>By confirming I accept this order doesn’t contain illegal/resticted items, if illegal/restricted items are found by Vasant Courier Partner, they may report it to the law enforcement authorities.Terms and Conditions
                                        </p>
                                    </label>
                                    <button className="enquiry-btn" type="submit" >Confirm Enquiry</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    {
                        params.enquirytype === "foodwrapping" && <div className='do-dont-text col-lg-4'>
                            <div className='do-dont-innertext'>
                                <div className='do-dont-title'>
                                    PICKLE AND POWDER WRAPPING
                                </div>
                                <div className='do-dont-tnc'>
                                    What's the purpose of pickle & powder wrap?
                                </div>
                                <span className='do-dont-tnc-text'>
                                    proper packaging of foods during shipping offers benefits such as
                                    preserving freshness, preventing contamination and damage, extending
                                    shelf life, complying with regulations, enhancing brand image, reducing
                                    transportation costs, providing convenience for consumers, promoting environmental
                                    sustainability, and reducing food waste.
                                </span>
                                <img src="/asset/images/courier/hand-drawn-transportation-concept-with-truck_23-2149161304.jpg" alt="vehicle" className='courier-vehicle-img' />

                            </div>
                        </div>
                    }
                    {
                        params.enquirytype === "airbubblewrapping" && <div className='do-dont-text col-lg-4'>
                            <div className='do-dont-innertext'>
                                <div className='do-dont-title'>
                                    AIR BUBBLE WRAPPING
                                </div>
                                <div className='do-dont-tnc'>
                                    What's the purpose of air bubble wrap?
                                </div>
                                <span className='do-dont-tnc-text'>
                                    proper packaging of foods during shipping offers benefits such as
                                    preserving freshness, preventing contamination and damage, extending
                                    shelf life, complying with regulations, enhancing brand image, reducing
                                    transportation costs, providing convenience for consumers, promoting environmental
                                    sustainability, and reducing food waste.
                                </span>
                                <img src="/asset/images/courier/hand-drawn-transportation-concept-with-truck_23-2149161304.jpg" alt="vehicle" className='courier-vehicle-img' />

                            </div>
                        </div>
                    }
                    {
                        params.enquirytype === "bubblewrapping" && <div className='do-dont-text col-lg-4'>
                            <div className='do-dont-innertext'>
                                <div className='do-dont-title'>
                                    Bubble wrapping
                                </div>
                                <div className='do-dont-tnc'>
                                    What's the purpose of bubble wrap?
                                </div>
                                <span className='do-dont-tnc-text'> The revolutionary bubble wrap is one
                                    of the most popular protective packaging solutions available. Using
                                    bubbles, packaging things well, is a convenient and extremely effective way of protecting fragile items and food
                                    items like Home made snacks etc...during shipping, moving, and storing due to its soft cushioning.
                                </span>
                                <img src="/asset/images/courier/hand-drawn-transportation-concept-with-truck_23-2149161304.jpg" alt="vehicle" className='courier-vehicle-img' />

                            </div>
                        </div>
                    }
                    {
                        params.enquirytype === "vaccumpacking" && <div className='do-dont-text col-lg-4'>
                            <div className='do-dont-innertext'>
                                <div className='do-dont-title'>
                                    VACCUM PACKING
                                </div>
                                <div className='do-dont-tnc'>
                                    What's the purpose of vaccum packing?
                                </div>
                                <span className='do-dont-tnc-text'>
                                    Vacuum sealing protects food from dehydration and freezer burn. Freezer burn occurs when water gets into your food. The vacuum bag keeps your food from contact with the air, so moisture cannot evaporate, making it an excellent
                                    barrier from the elements. At its very foundation, vacuum sealing is the
                                    act of removing oxygen from bags or containers, creating an airtight seal
                                    that protects food from freezer burn and bacteria growth.
                                </span>
                                <img src="/asset/images/courier/hand-drawn-transportation-concept-with-truck_23-2149161304.jpg" alt="vehicle" className='courier-vehicle-img' />

                            </div>
                        </div>
                    }
                    {
                        params.enquirytype === "suitcasewrapping" && <div className='do-dont-text col-lg-4'>
                            <div className='do-dont-innertext'>
                                <div className='do-dont-title'>
                                    SUITCASE WRAPPING
                                </div>
                                <div className='do-dont-tnc'>
                                    What's the purpose of suitcase wrapping?
                                </div>
                                <span className='do-dont-tnc-text'>
                                    Baggage handlers at airports, for example, often have to move luggage quickly and
                                    may not always be gentle. By wrapping their luggage, travelers can mitigate the
                                    risk of their bags being mishandled and potentially damaged.
                                    Our Wraps Shield from Drops, Collisions, and Rough Handling.
                                    protects your luggage from damage, deters thieves, weather-proofs it, makes it
                                    easily identifiable, and reduces the risk of someone slipping illicit goods into
                                    your bag
                                </span>
                                <img src="/asset/images/courier/hand-drawn-transportation-concept-with-truck_23-2149161304.jpg" alt="vehicle" className='courier-vehicle-img' />

                            </div>
                        </div>
                    }
                    {
                        params.enquirytype === "requestPickup" &&
                        <div className='do-dont-text col-lg-4'>
                            <div className='do-dont-innertext'>
                                <div className='do-dont-title'>Some do’s & Dont’s</div>
                                <div className='do-dont-tnc'>
                                    No Purchases - <span className='do-dont-tnc-text'> Delivery partner would not be able to make any purchase</span>
                                </div>
                                <div className='do-dont-tnc'>
                                    Package Weight - <span className='do-dont-tnc-text'>We cannot deliver items that can’t be easily carried on bike</span>
                                </div>
                                <div className='do-dont-tnc'>
                                    No Autos/Cabs - <span className='do-dont-tnc-text'>We will not be able to get something transported via these</span>
                                </div>
                                <div className='do-dont-tnc'>
                                    Restricted/Illegal Item - <span className='do-dont-tnc-text'>Please don’t hand over any restricted item</span>
                                </div>
                                <img src="/asset/images/courier/hand-drawn-transportation-concept-with-truck_23-2149161304.jpg" alt="vehicle" className='courier-vehicle-img' />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default CouriereEnquiry