import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AddressHeader } from "./components/AddressHeader";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { OfferHeader } from "./components/offerHeader";
import { Home } from "./components/pages/Home/Home";
import { ProductDetail } from "./components/pages/ProductDetail/ProductDetail";
import { AboutUs } from "./components/pages/AboutUs/AboutUs";
import { Login } from "./components/pages/Login/Login";
import { SignIn } from "./components/pages/Login/Signin";
import { Account } from "./components/pages/Account/Account";
import { Checkout } from "./components/pages/Checkout/Checkout";
import Cart from "./components/pages/cart/cart";

import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./redux/Store";

import { Addaddress } from "./components/pages/AddAddress";
import { ProductByCategory } from "./components/pages/ProductByCategory/ProductByCategory";
import { useEffect } from "react";
import { getCategory } from "./redux/Category/CategorySlice";
import { getAllBrand } from "./redux/brand/BrandSlice";
import {
  getAllOfferProduct,
  getAllProduct,
  getAllRecommendedProduct,
  getAllTrendingProduct,
} from "./redux/product/ProductSlice";
import { ProfileRegister } from "./components/pages/ProfileRegister/ProfileRegister";
import { WishList } from "./components/WishList";
import { SearchPage } from "./components/SearchPage";
import { OrderSuccess } from "./components/orderSuccess";
import { getWishlist } from "./redux/wishlist/WishlistSlice";
import { getOrderbyClId } from "./redux/order/orderSlice";
import { getCartTotal } from "./redux/cart/CartSlice";
import Error404 from "./components/Error404";
import BottomSheet from "./components/BottomSheet";
import ScrollinText from "./components/ScrollinText";
import SubmitCourierForm from "./courierpage-components/Home/SubmitCourierForm";
import CourierHomePage from "./courierpage-components/Pages/CourierHomePage";
import CouriereEnquiry from "./courierpage-components/FormFillup/CouriereEnquiry";
import { getCourierList } from "./redux/courier/CourierSlice";
import { getCourierPrice } from "./redux/courierPrice/courierpriceSlice";
import CourierPrice from "./courierpage-components/FormFillup/CourierPrice";
import OrderSummary from "./components/pages/OrderSummary";


function App() {
  const { loginData } = useSelector((store) => store.Athentication);
  const { clientOrder } = useSelector((store) => store.Order);

  const dispatch = useDispatch();
  useEffect(() => {
    const initailSetup = async () => {
      try {
        dispatch(getCategory());
        dispatch(getAllTrendingProduct());
        dispatch(getAllRecommendedProduct());
        dispatch(getAllOfferProduct());
        dispatch(getAllBrand());
        dispatch(getAllProduct());
        dispatch(getWishlist(loginData._id));
        dispatch(getOrderbyClId(loginData._id));
        dispatch(getCartTotal());
        dispatch(getCourierList());
        dispatch(getCourierPrice())
      } catch (error) {}
    };
    initailSetup();
  }, []);
  return (
    <>
      <BrowserRouter>
        <ScrollinText/>
        <AddressHeader/>
        <Header/>
        <Routes>
          <Route path="/" element={<CourierHomePage />} />
          <Route path="/shop" element={<Home />} />
          <Route
            path="/productdetail/:productUrl"
            element={<ProductDetail />}
          />
          <Route
            path="/productbycategory/:slugUrl"
            element={<ProductByCategory />}
          />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/searchby/:keyword" element={<SearchPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<SignIn />} />
          <Route path="/account" element={<Account />} />
          <Route path="/profileregister" element={<ProfileRegister />} />
          <Route path="/addaddress" element={<Addaddress />} />
          <Route path="/wishlist" element={<WishList />} />
          <Route path="/ordersuccess" element={<OrderSuccess />} />
          <Route path="/ordersummary/:awb" element={<OrderSummary />} />
          <Route path="*" element={<Error404 />} />

            {/*   */}
          <Route path="/courierenquiry/:enquirytype" element={<CouriereEnquiry />} />
          <Route path="/courierprice" element={<CourierPrice />} />

        </Routes>
        <BottomSheet />
        <Footer />
      </BrowserRouter>
      {/* <PaymentSuccess/> */}
      {/* <DownloadAppForWeb/> */}
      {/* <DownloadAppForApp/> */}
    </>
  );
}

export default App;
