import React from 'react';
import { useSelector } from 'react-redux';

function AccountDetails() {
  const { loginData } = useSelector((store) => store.Athentication);
  return (
    <>
      <div style={{ textAlign: 'justify',margin:'2px' ,padding:'10px',boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'}}>
        <h3>Hello Mr.{loginData.name}</h3>
        <p>
          From your account dashboard. you can easily check & view your recent orders, manage your shipping and billing addresses and edit your password and account details.
        </p>
      </div>
    </>
  )
}

export default AccountDetails