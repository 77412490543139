import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState={
    courierRequest:[],
    isLoading:true
}

export const courierRequestPost=createAsyncThunk(
    "courier/courierpost",
    async(formdata,thunkAPI)=>{
        try{
            const config={
                Headers:{
                    "content-type":"application/json"
                }
            }
            const url=`${Baseurl}/api/v1/requestcourier/new`;
            const resp=await axios.post(url,formdata,config);
            console.log(resp.data,"resp");
            return resp.data
        }catch(error){
            return thunkAPI.rejectWithValue("Courier request not created");
        }
    }
)

const RequestCourierSlice=createSlice({
    name:"CourierRequest",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(courierRequestPost.pending,(state)=>{
            state.isLoading=false
        })
        .addCase(courierRequestPost.fulfilled,(state,action)=>{
            state.courierRequest=[action.payload.courierRequest,...state.courierRequest]
        })
        .addCase(courierRequestPost.rejected,(state)=>{
            state.isLoading=false
        })
    }
})

export default RequestCourierSlice.reducer;