import React from 'react'
import { Link } from 'react-router-dom'

function Error404() {
    return (
        <>
            <section className="section-404 " style={{ display: 'flex', flexDirection:'column',justifyContent: "center", alignItems: 'center' }}>

                <h5>
                    Oops!!!the page you are looking for could not be found.
                </h5>
                <Link to="/" className='tocart'>
                    <button
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',border:'none',outline:'none' }}
                    >
                        Back To Home Screen
                    </button>
                </Link>
            </section>
        </>
    )
}

export default Error404
