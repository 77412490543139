import React from 'react';
import { FaChevronRight } from "react-icons/fa";
import { Navigate, useNavigate } from 'react-router-dom';

function OurServices() {
    const navigate = useNavigate()
    return (
        <>
            <div className='padding-50 our-services'>
                <div className='side-heading-mainheading'>
                    OUR SERVICES
                </div>
                <div className='side-heading-subheading'>
                    COURIER SERVICES
                </div>
                <div className='hr-line'></div>
                <div className='row'>
                    <div className='col-lg-4 col-sm-4 col-6'>
                        <div className='services-card card' onClick={() => { navigate("/courierenquiry/requestPickup") }}>
                            <div className="services-card-body card-body">
                                <img src="/asset/images/courier/two-delivery-women-cooperating-while-checking-data-packages-office_637285-6601.webp" alt="Courier-boy" width="100%" />
                            </div>
                            <div className="card-footer services-card-footer">
                                <div className='services-card-text' onClick={() => { navigate("/courierenquiry/requestPickup") }}>Request Courier Pickup <FaChevronRight /></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-4 col-6'>
                        <div className='services-card card' onClick={() => {navigate("/courierprice") }}>
                            <div className="services-card-body card-body">
                                <img src="/asset/images/courier/courier-rate.webp" alt="Courier-rates" width="100%" />
                            </div>
                            <div className="card-footer services-card-footer">
                                <div className='services-card-text' onClick={() => {navigate("/courierprice") }}>Get Courier Rates <FaChevronRight /></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-4 col-6'>
                        <div className='services-card card' onClick={() => { navigate("/courierenquiry/foodwrapping") }}>
                            <div className="services-card-body card-body">
                                <img src="/asset/images/courier/pickle-powder.webp" alt="pickle-packing" width="100%" />
                            </div>
                            <div className="card-footer services-card-footer">
                                <div className='services-card-text' onClick={() => { navigate("/courierenquiry/foodwrapping") }}>Pickle,Powder Packing <FaChevronRight /></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-4 col-6'>
                        <div className='services-card card' onClick={() => { navigate("/courierenquiry/bubblewrapping")}}>
                            <div className="services-card-body card-body">
                                <img src="/asset/images/courier/young-couple-moving-into-new-home_23-2149199086.webp" alt="" width="100%" />
                            </div>
                            <div className="card-footer services-card-footer">
                                <div className='services-card-text' onClick={() => { navigate("/courierenquiry/bubblewrapping")}}>Request Bubble Wrapping <FaChevronRight /></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-4 col-6'>
                        <div className='services-card card' onClick={() => { navigate("/courierenquiry/suitcasewrapping")}}>
                            <div className="services-card-body card-body">
                                <img src="/asset/images/courier/luggage-track_266732-13651.webp" alt="suitcase-wrappingimage" width="100%" />
                            </div>
                            <div className="card-footer services-card-footer">
                                <div className='services-card-text' onClick={() => { navigate("/courierenquiry/suitcasewrapping")}}>Suitcase Wrapping <FaChevronRight /></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-4 col-6'>
                        <div className='services-card card' onClick={() => { navigate("/courierenquiry/vaccumpacking") }}>
                            <div className="services-card-body card-body">
                                <img src="/asset/images/courier/pears-packaged-plastic-isolated-white-background_123827-20523.webp" alt="" width="100%" />
                            </div>
                            <div className="card-footer services-card-footer">
                                <div className='services-card-text' onClick={() => { navigate("/courierenquiry/vaccumpacking") }}>Vaccum Packing <FaChevronRight /></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-4 col-6'>
                        <div className='services-card card' onClick={() => { navigate("/courierenquiry/airbubblewrapping")}}>
                            <div className="services-card-body card-body">
                                <img src="/asset/images/courier/healthy-products.webp" alt="" width="100%" />
                            </div>
                            <div className="card-footer services-card-footer">
                                <div className='services-card-text' onClick={() => { navigate("/courierenquiry/airbubblewrapping")}}>Air Bubble Packing <FaChevronRight /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurServices