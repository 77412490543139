import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { IoPerson } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { Login } from "../components/pages/Login/Login";
import { SignIn } from "../components/pages/Login/Signin";
import Modal from "react-bootstrap/Modal";

const SecondHeader = () => {
    const { isAuth, loginData } = useSelector((store) => store.Athentication);
    const { wishlist } = useSelector((store) => store.Wishlist);
    const { CartItems } = useSelector((state) => state.Cart || {});
    const [cartItem, setCartItem] = useState(0);
    const [showRegister, setShowRegister] = useState(true);
    const [showLogin, setShowLogin] = useState(false);

    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true);
    };

    function profileClick() {
        if (isAuth) {
            navigate("/account");
        } else {
            navigate("/login")
        }
    }
    function showWishlist() {
        if (isAuth && wishlist.length >= 1) {
            navigate(`/wishlist`);
        } else if (!isAuth) {
            alert("Pls Login To See Your Wishlist")
        } else if (wishlist.length === 0) {
            alert("Your wishlist is empty")
        }
    }
    const showCart = () => {
        if (CartItems.length >= 1) {
            navigate("/cart")
        } else {
            alert("Oops!! your cart is empty")
        }
    }
    const openRegister = () => {
        setShowRegister(true);
        setShowLogin(false);
    };
    const openLogin = () => {
        setShowLogin(true);
        setShowRegister(false);
    };
    const handleClose = () => {
        setShow(false);
    };
    return (
        <>
            <div className='second-header' >
                <div>
                    <div className="brand">
                        <Link to="/"><img src="/asset/images/logo/vasantcouriers.jpg" alt="vasantcouriers" style={{ height: '100px', paddingTop: '10px ' }} /></Link>
                    </div>
                </div>
                <div className="rightpanel">
                    <span
                        onClick={() => {
                            profileClick();
                        }}
                        className="p10"
                    >
                        <IoPerson />
                    </span>

                    <span className="p10" onClick={() => { showWishlist() }} style={{ position: "relative" }}>
                        <FaRegHeart />
                        <span
                            className="badge bg-light text-dark"
                            style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                fontSize: "8px",
                                border: "1px solid #000",
                            }}
                        >
                            {wishlist.length}
                        </span>
                    </span>
                    <span className="p10" style={{ position: "relative" }} onClick={() => { showCart() }}>
                        <FaCartShopping />
                        <span
                            className="badge bg-light text-dark"
                            style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                fontSize: "8px",
                                border: "1px solid #000",
                            }}
                        >
                            {CartItems.length}
                        </span>
                    </span>

                </div>
            </div>
        </>
    )
}

export default SecondHeader