import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  wishlist: localStorage.getItem("wishlist")
    ? JSON.parse(localStorage.getItem("wishlist"))
    : [],
  isWishlistLoading: localStorage.getItem("wishlist") ? false : true,
  wishlistLoading: localStorage.getItem("wishlist") ? false : true,
};

export const WishlistPost = createAsyncThunk(
  "Wishlist/WishlistPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/wishlist/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("wishlist not create");
    }
  }
);

export const getWishlist = createAsyncThunk(
  "wishlist/getWishlist",
  async (clientid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/wishlist/wishlistbyclientid/${clientid}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("wishlist Not Found");
    }
  }
);

export const wishlistDelete = createAsyncThunk(
  "wishlist/wishlistDelete",
  async (data, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/wishlist/delete/${data._id}`;
      const resp = await axios.delete(url,config);
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue("wishlist Not create");
    }
  }
);

const WishlistSlice = createSlice({
  name: "Wishlist",
  initialState,
  reducers: {
    addToWishlist(state, action) {
      let itemIndex = -1;
      itemIndex = state.wishlist.findIndex(
        (item) => item.optionId === action.payload.optionId
      );

      if (itemIndex >= 0) {
      } else {
        const tempProducts = action.payload;
        state.wishlist.push(tempProducts);
      }

      localStorage.setItem("wishlist", JSON.stringify(state.wishlist));
    },
    deletewishlistItem(state, action) {
      state.wishlist = state.wishlist.filter(
        (wishlist) => wishlist._id !== action.payload._id
      );
      localStorage.setItem("wishlist", JSON.stringify(state.wishlist));
    },
    clearWishlistItem(state, action) {
      state.CartItems = [];
      state.cartTotalQuantity = 0;
      state.cartTotalAmount = 0;
      state.netPayable = 0;
      state.cartTotalMrp = 0;
      state.cartPackQuantity = 0;
      state.Cartloading = !state.Cartloading;
      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(WishlistPost.pending, (state) => {
        state.isWishlistLoading = true;
      })
      .addCase(WishlistPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.wishlist = [...state.wishlist, action.payload.wishlist];
          localStorage.setItem("wishlist", JSON.stringify(state.wishlist));
          state.isWishlistLoading = false;
        }
      })
      .addCase(WishlistPost.rejected, (state, action) => {
        state.isWishlistLoading = true;
      })
      .addCase(getWishlist.pending, (state) => {
        state.isWishlistLoading = true;
      })
      .addCase(getWishlist.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.wishlist = action.payload.wishlist;
        }
        localStorage.setItem("wishlist", JSON.stringify(state.wishlist));
        state.isWishlistLoading = false;
      })

      .addCase(getWishlist.rejected, (state) => {
        state.isWishlistLoading = true;
      })
      .addCase(wishlistDelete.pending, (state) => {
        state.isWishlistLoading = true;
      })
      .addCase(wishlistDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.wishlist = state.wishlist.filter(
            (wishlist) => wishlist._id !== action.payload.wishlist._id
          );
        }
        state.isWishlistLoading = false;
        localStorage.setItem("wishlist", JSON.stringify(state.wishlist));
      })

      .addCase(wishlistDelete.rejected, (state) => {
        state.isWishlistLoading = true;
      });
  },
});

export const { addToWishlist, deletewishlistItem ,clearWishlistItem} = WishlistSlice.actions;
export default WishlistSlice.reducer;
