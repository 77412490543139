import React from 'react';
import { CiDeliveryTruck } from "react-icons/ci";
import { BsHandbag } from "react-icons/bs";
import { MdMyLocation } from "react-icons/md";
import { MdOutlineAccessTime } from "react-icons/md";

function BusinessFeatures() {
    return (
        <>
            <div className='courier-features'>
                <div className="row">
                    <div className="col-lg-4 ">
                        <div className='features-sidetitle'>AMAZING FEATURES
                        </div>
                        <div className='features-sidetext'>
                            THE BEST, THE FASTEST AND THE PERFECT COURIER SERVICES FOR YOUR HAPPINESS
                        </div>
                        <div className='hr-line'><hr /></div>
                        <p className='features-remainingtext'>Having read all the details, the commitments, the feedbacks and all about our systems and procedures, you should just pick up your phone and ask us for</p>
                    </div>
                    <div className="col-lg-4 row">
                        <div className='features-box col-md-6 col-lg-12'>
                            <div><CiDeliveryTruck className="logo"/></div>
                            <div>
                                <div className='features-box-title'>BOOK THE CONSIGNMENT</div>
                                <p className='features-box-text'>Fill in the form, inject the required details and your parcels are booked for delivery. The process will start on</p>
                            </div>
                        </div>
                        <div className='features-box col-md-6 col-lg-12'>
                            <div><BsHandbag className="logo"/></div>
                            <div>
                                <div className='features-box-title'>
                                    SAFE SHIPMENT
                                </div>
                                <p className='features-box-text'>
                                    Your parcels are going to be shipped safely. No harm to your things. Careful shipment will get your
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 row">
                        <div className='features-box col-md-6 col-lg-12'>
                            <div><MdMyLocation className="logo"/></div>
                            <div>
                                <div className='features-box-title'>ADVANCED TRACKING</div>
                                <p className='features-box-text'>Check on the tracking tab, type the order number and know the current place of your product. Tracking lets you know about it.</p>
                            </div>
                        </div>
                        <div className='features-box col-md-6 col-lg-12'>
                            <div><MdOutlineAccessTime className="logo"/></div>
                            <div>
                                <div className='features-box-title'>
                                    TIMELY DELIVERY
                                </div>
                                <p className='features-box-text'>
                                    We guarantee you to deliver parcels on time. Punctuality is a core value for us. You will get to know as you
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

        </>
    )
}

export default BusinessFeatures