import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IoMdEye } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import {
  authActions,
  validateEmail,
  clientLogin, is, loginAuthentication
} from "../../../redux/athentication/Athentication";
import axios from "axios";
import { Baseurl } from "../../../config/BaseUrl";
import SecondHeader from "../../SecondHeader";

export function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnShow, setBtnShow] = useState(false);
  const [otpvalue, setOtpValue] = useState("")
  const [otpSave, setOtpSave] = useState("")


  const mobChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobileError("");
    setMobile(value);
    if (value.length === 10) {
      setMobileError("");
    } else {
      setMobileError("Please Enter Valid Number");
    }
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (mobile.length === 10) {

      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }

      const formData = {
        mobile: mobile,
        OTP: OTP,
      }
      setOtpSave(formData);
      dispatch(loginAuthentication(formData))
      setBtnShow(true)
    } else {
      setMobileError("Please enter valid number")
    }
  };


  const resendOtp = () => {
    setOtpValue("");
    dispatch(loginAuthentication(otpSave))
  }

  const verifyOtp = async (e) => {
    let validOtp = String(otpvalue);
    if (validOtp.length === 6) {
      setMobileError("");
      if (validOtp === otpSave.OTP) {
        try {
          const config = {
            Headers: {
              "Content-Type": "application/json"
            }
          }
          const formData = {
            mobile: mobile
          }

          const userDetails = await dispatch(clientLogin(formData));
          if (userDetails.payload.success) {
            const loginDetails = {
              ...userDetails.payload.client,
              isAuth: true
            }
            dispatch(authActions.signIn(loginDetails));
            dispatch(authActions.showModal(false))
            navigate("/")
          } else if (!userDetails.payload.success) {
            toast.error("Please register to login", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              zIndex: 11000,
            });
          }
        } catch (error) {

        }
      }
    } else {
      setErrorMessage("Please enter correct OTP")
    }
  }

  return (
    <>
    <SecondHeader/>
      <div className="pt-4 mob-login login">
        <div className="login-inner">
                <div className="imgcontainer">
                  <img
                    src="/asset/images/thumb/process-themb.png"
                    alt="Avatar"
                    className="avatar"
                  />
                </div>
          {
            btnShow ? (<>
              <form id="login-form" onSubmit={(e) => verifyOtp(e)} >
                <label htmlFor="uname">
                  <b>Phone no.</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Phone No. "
                  id="username1"
                  className="form-control login-input"
                  tabIndex={1}
                  name="uname"
                  required
                  value={mobile}
                />
                <div onClick={() => { setBtnShow(!btnShow); setOtpValue("") }} style={{ fontSize: '14px', textAlign: 'end', cursor: 'pointer' }}>Edit</div>
                <div>
                  <input
                    type="tel"
                    className="form-control login-input"
                    maxLength={6}
                    placeholder="One Time Password"
                    value={otpvalue}
                    onChange={(e) => setOtpValue(e.target.value)}
                    style={{ border: errorMessage ? '1px solid red' : '' }}
                  />
                </div>
                <div onClick={() => resendOtp()} style={{ fontSize: '14px', textAlign: 'end', cursor: 'pointer' }}>
                  resend otp
                </div>
                <button type="submit" className="login-btn" >Verify Otp</button>
                <div
                  className="footer-container"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                </div>
              </form>
            </>) : (<>
              <form id="login-form" onSubmit={(e) => handleLoginSubmit(e)} >
                <div className="">
                  <label htmlFor="uname">
                    <b>Phone no.</b>
                  </label>
                  <input
                    className="form-control login-input"
                    name="uname"
                    required
                    type="tel"
                    placeholder="Phone number"
                    value={mobile}
                    minLength={10}
                    maxLength={10}
                    pattern="[6-9]{1}[0-9]{9}"
                    onChange={(e) => mobChange(e)}
                  />
                </div>
                <button type="submit" className="login-btn" >Send Otp</button>
              </form>
              <div className="register-now">------New User------</div>
              <button type="submit" className="login-btn" onClick={()=>navigate("/register")}>Regsiter Now</button>
            </>)
          }
        </div>
      </div >
      <div className="pt-4  desktop-login">
        <div className="login-inner">
                <div className="imgcontainer">
                  <img
                    src="/asset/images/thumb/process-themb.png"
                    alt="Avatar"
                    className="avatar"
                  />
                </div>
          {
            btnShow ? (<>
              <form id="login-form" onSubmit={(e) => verifyOtp(e)} >
                <label htmlFor="uname">
                  <div className="login-label">Phone no.</div>
                </label>
                <input
                  type="text"
                  placeholder="Enter Phone No. "
                  id="username1"
                  className="form-control login-input"
                  tabIndex={1}
                  name="uname"
                  required
                  value={mobile}
                />
                <div onClick={() => { setBtnShow(!btnShow); setOtpValue("") }} className="text-small">Edit</div>
                <div>
                  <input
                    type="tel"
                    className="form-control login-input"
                    maxLength={6}
                    placeholder="One Time Password"
                    value={otpvalue}
                    onChange={(e) => setOtpValue(e.target.value)}
                    style={{ border: errorMessage ? '1px solid red' : '' }}
                  />
                </div>
                <div onClick={() => resendOtp()} className="text-small">
                  resend otp
                </div>
                <button type="submit" className="login-btn" >Verify Otp</button>
                <div
                  className="footer-container"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                </div>
              </form>
            </>) : (<>
              <form id="login-form" onSubmit={(e) => handleLoginSubmit(e)} >
                <div className="">
                  <label htmlFor="uname">
                    <div className="login-label"> Phone no.</div>
                  </label>
                  <input
                    className="form-control login-input"
                    name="uname"
                    required
                    type="tel"
                    placeholder="Enter Phone number"
                    value={mobile}
                    minLength={10}
                    maxLength={10}
                    pattern="[6-9]{1}[0-9]{9}"
                    onChange={(e) => mobChange(e)}
                  />
                </div>
                <button type="submit" className="login-btn" >Send Otp</button>
              </form>
              <div className="register-now">------New User------</div>
              <button type="submit" className="login-btn" onClick={()=>navigate("/register")}>Register Now</button>
            </>)
          }
        </div>
      </div >
      <ToastContainer/>
    </>
  );
}
