import { FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Addaddress } from "../AddAddress";
import {
  removefromCart,
  decrementCart,
  addtoCart,
  getCartTotal,
  clearCart
} from "../../../redux/cart/CartSlice";
import { useEffect, useState } from "react";
import { OrderPost } from "../../../redux/order/orderSlice"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { currAddress, modalShow } from "../../../redux/address/Address"
import { courierPost, getCourierList } from "../../../redux/courier/CourierSlice";
import SecondHeader from "../../SecondHeader";
import { IoMdArrowBack } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";

export function Checkout() {
  const { isAuth, loginData } = useSelector(
    (store) => store.Athentication
  );
  const { addresses, currentAddress, isModalShowing } = useSelector((store) => store.Address);
  const { ordersLoading } = useSelector((store) => store.Order)
  const { courierList } = useSelector((store) => store.Courier)
  const [currentClientAddress, setCurrentClientAddress] = useState("")
  const [btnLoading, setBtnLoading] = useState(false)
  const [awb, setAwb] = useState("")
  const {
    CartItems,
    cartTotalPrice,
    cartTotalAmount,
    netPayable,
    logisticAmount

  } = useSelector((state) => state.Cart);
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const handleRemoveCart = (cartItem) => {
    dispatch(removefromCart(cartItem));
  };
  const [addressModal, setAddressModal] = useState(false);
  const changeAddress = () => setAddressModal(!addressModal);

  // useEffect(() => {
  //   if (currentAddress.length >= 1) {
  //     setCurrentClientAddress(currentAddress)
  //     console.log("currentAddress");
  //   } else {
  //     setCurrentClientAddress(addresses[0])
  //     console.log("currentAddress");
  //   }

  // }, [currentAddress])

  const selectClientAddress = (evt, data) => {
    dispatch(currAddress(data))
  }

  useEffect(() => {
    dispatch(getCourierList())
  }, [])

  const handleOrderCheckout = async () => {
    setBtnLoading(!btnLoading)
    var date = new Date();
    date.setDate(date.getDate() + 5);
    const currAddress = currentAddress ? currentAddress : addresses[0]
    if (cartTotalAmount > 0 && isAuth && currAddress) {


      const orderData = {
        consignee: currAddress.name,
        destination: `${currAddress.address},${currAddress.city},${currAddress.state},${currAddress.pincode}`,
        mobile: currAddress.phn,
        type: "Ecomm",
        clientId: loginData._id,
        clientname: currAddress.name,
        clientEmail: currAddress.email,
        clientPhone: currAddress.phn,
        totalAmount: Number(cartTotalAmount),
        deliverCharge: Number(0),
        netPayable: Number(cartTotalAmount),
        orderproducts: CartItems,
        shippingInfo: {
          address: currAddress.address,
          city: currAddress.city,
          state: currAddress.state,
          country: "India",
          pinCode: currAddress.pincode,
          type: currAddress.type
        },
        paymentInfo: {
          mode: "COD",
          status: "NA",
          paidAt: date,
        }
      };
      const order = await dispatch(OrderPost(orderData));
      if (order.payload.success) {
        setBtnLoading(false)
        toast.success("ORDER PLACED SUCCESSFULL", {
          position: "top-center",
          autoClose: 500,
        });
        navigate("/ordersuccess");
        dispatch(clearCart())
      }

    } else if (!currAddress) {
      toast.error("Please add address in order to checkout", {
        position: "top-right",
        autoClose: 1000,
      });
    } else if (!isAuth) {
      toast.error("Please login in order to checkout", {
        position: "top-right",
        autoClose: 1000,
      });
    } else if (cartTotalAmount <= 0) {
      toast.error("Please add some item  in your cart", {
        position: "top-right",
        autoClose: 1000,
      });
    }
  }
  return (
    <>
      <SecondHeader />
      <div className="p100 checkout-top  cartpage desktop-checkout">
        <div className="row">
          <div className="col-lg-6 ">
            <div>
              Add Address
            </div>
            <Button color="danger" className="mt-3" onClick={() => dispatch(modalShow(true))}>
              Add New Address
            </Button>

            <div>
              {
                currentAddress ? (
                  <div className="Address-box" >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>Name:{currentAddress.name}</div>
                      <div style={{ color: '#e91e63' }}>
                        <Button onClick={changeAddress}>
                          Change Address
                        </Button>
                      </div>
                    </div>
                    <div>
                      Mobile:{currentAddress.phn}
                    </div>
                    <div>
                      Alt-Mob:{
                        currentAddress.altPhn
                      }
                    </div>
                    <div>
                      Address:{currentAddress.address}
                    </div>
                    <div>
                      City:{
                        currentAddress.city
                      }
                    </div>
                    <div>
                      State:{
                        currentAddress.state
                      }
                    </div>
                    <div>
                      Pincode:{
                        currentAddress.pincode
                      }
                    </div>
                  </div>
                ) : (
                  addresses.length > 0 && (
                    <div className="Address-box" >
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>Name:{addresses[0].name}</div>
                        <div style={{ color: '#e91e63' }}>
                          <Button onClick={changeAddress}>
                            Change Address
                          </Button>
                        </div>
                      </div>
                      <div>
                        Mobile:{addresses[0].phn}
                      </div>
                      <div>
                        Alt-Mob:{
                          addresses[0].altPhn
                        }
                      </div>
                      <div>
                        Address:{addresses[0].address}
                      </div>
                      <div>
                        City:{
                          addresses[0].city
                        }
                      </div>
                      <div>
                        State:{
                          addresses[0].state
                        }
                      </div>
                      <div>
                        Pincode:{
                          addresses[0].pincode
                        }
                      </div>
                    </div>
                  )
                )
              }
            </div>
          </div>
          <Modal isOpen={isModalShowing}  >
            <ModalBody>
              <Addaddress />
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Modal>
          <Modal isOpen={addressModal} toggle={changeAddress} >
            <ModalBody>
              {
                addresses.map((singleAddress, index) =>
                  <div className="Address-box" key={index} >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>Name:{singleAddress.name}</div>
                      <div style={{ color: '#e91e63' }}>
                        <input type="radio" name="" id="" value={singleAddress._id} onChange={(e) => { selectClientAddress(e, singleAddress) }} />
                      </div>
                    </div>
                    <div>
                      Mobile:{singleAddress.phn}
                    </div>
                    <div>
                      Alt-Mob:{
                        singleAddress.altPhn
                      }
                    </div>
                    <div>
                      Address:{singleAddress.address}
                    </div>
                    <div>
                      City:{
                        singleAddress.city
                      }
                    </div>
                    <div>
                      State:{
                        singleAddress.state
                      }
                    </div>
                    <div>
                      Pincode:{
                        singleAddress.pincode
                      }
                    </div>
                  </div>
                )
              }
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Modal>
          <div className="col-lg-6  cartpage-rightpanel">
            <div className="table-responsive">
              <table className="table table-checkout">
                <thead>
                  <tr>
                    <th scope="col">Preview</th>
                    <th scope="col">Product</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Subtotal</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {CartItems.map((cartItem, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={cartItem.mblimg}
                          className="product-image"
                          alt="product-image"
                          onClick={() => {
                            navigate(`/productdetail/${cartItem.slugUrl}`);
                          }}
                        />
                      </td>
                      <td>
                        <div>{cartItem.name}</div>
                        <div>&#8377; {cartItem.packsize.price}</div>
                      </td>
                      <td>
                        <div className="product-count">
                          <div
                            onClick={() => {
                              dispatch(decrementCart(cartItem));
                              dispatch(getCartTotal());
                            }}
                          >
                            -
                          </div>
                          <div>{cartItem.Qty}</div>
                          <div
                            onClick={() => {
                              dispatch(addtoCart(cartItem));
                              dispatch(getCartTotal());
                            }}
                          >
                            +
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>&#8377; {cartItem.TotalAmount}</div>
                      </td>
                      <td
                        onClick={() => {
                          handleRemoveCart(cartItem);
                        }}
                      >
                        <FaTrashAlt />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-5">
              <div className="billtitle">Cart Totals</div>
              <div className="cartpage-rightpanel-subtotal">
                <div className="billtitle">Subtotal</div>
                <div>&#8377; {cartTotalAmount}</div>
              </div>
              <hr />
              <div className="cartpage-rightpanel-subtotal">
                <div className="billtitle">Total</div>
                <div>₹{cartTotalAmount} </div>
              </div>
              <button
                className="proceed-checkout mt-2"
                onClick={() => {
                  handleOrderCheckout();
                }}
              >
                Proceed to checkout
              </button>
            </div>
          </div>
        </div>
      </div>


      <section className="mob-cart">
        <div className="container" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}> 
          <div className="">
            <a rel="nofollow" onClick={() => navigate("/")}>
              <IoMdArrowBack
                size={20}
                className="mx-1"
                style={{ margin: "0%" }}
              />
            </a>
            <span /> checkout
          </div>
          <div>
            <Button color="danger" style={{fontSize:"12px"}} onClick={() => dispatch(modalShow(true))}>
              Add New Address
            </Button>
          </div>
        </div>
        <div style={{ border: '1px solid #bbb', margin: '8px', padding: '5px 8px', borderRadius: '6px', }}>
          {
            CartItems.map((individualItem, index) =>
              <div className="individual-item row" style={{ width: '100%' }}>
                <div className="col-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={individualItem.mblimg} alt="" className="cart-img" />
                </div>
                <div className="col-8 p-0">
                  <div className="pastorder-title">name: <span className="ps-2">{individualItem.name}</span></div>
                  <div className="pastorder-title"> price: <span className="ps-2">&#8377; {individualItem.packsize.price}</span> </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="pastorder-title" style={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center', gap: '5px' }}>
                      Qty:
                      <span className="product-count-mob ps-2">
                        <button
                          style={{ backgroundColor: 'unset', border: 'none', outline: 'none' }}
                          type="button"
                          onClick={() => {
                            if (individualItem.Qty > 0) {
                              dispatch(decrementCart(individualItem));
                              dispatch(getCartTotal());
                            }
                          }}
                        >
                          -
                        </button>
                        <input
                          // className="form-control middlepart"
                          type="text"
                          name="quantity"
                          defaultValue={0}
                          value={individualItem.Qty}
                          contentEditable="false"
                          style={{ width: '50px', border: 'none', textAlign: 'center' }}
                        />
                        <button
                          style={{ backgroundColor: 'unset', border: 'none', outline: 'none' }}
                          type="button"
                          onClick={() => {
                            dispatch(addtoCart(individualItem));
                            dispatch(getCartTotal());
                          }}
                        >
                          +
                        </button>
                      </span>
                    </div>
                    <div>
                      <div className="pe-3 "
                        onClick={() => {
                          handleRemoveCart(individualItem)
                        }}>
                        <BsTrashFill />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div className="cart-btm-top" style={{ border: '1px solid #bbb', margin: '8px', padding: '5px 8px', borderRadius: '6px' }}>
          <div className="divider center_icon mt-">
            <i className="fi-rs-fingerprint" />
          </div>
          <div className="total-cart" style={{ marginBottom: '5px' }}>
            <div className="total-cart-design">Price Details ({CartItems.length} Items)</div>
            <div >
              <div className="total-cart-arg">
                <div style={{ fontWeight: '600', color: '#555' }}>
                  Total Product Price:
                </div>
                <div style={{ color: 'green' }}>
                  {cartTotalAmount}
                </div>
              </div>
              <div className="total-cart-arg" style={{ borderTop: '1px solid #bbb', paddingTop: '5px' }}>
                <div style={{ fontWeight: '600', color: '#555' }}>
                  Order Total:
                </div>
                <div className="net-payable" style={{ color: 'green' }}>
                  {netPayable}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div >
          {
            currentAddress ? (
              <div className="Address-box" style={{ border: '1px solid #bbb' }} >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>Name:{currentAddress.name}</div>
                  <div style={{ color: '#e91e63' }}>
                    <Button onClick={changeAddress}>
                      Change Address
                    </Button>
                  </div>
                </div>
                <div>
                  Mobile:{currentAddress.phn}
                </div>
                <div>
                  Alt-Mob:{
                    currentAddress.altPhn
                  }
                </div>
                <div>
                  Address:{currentAddress.address}
                </div>
                <div>
                  City:{
                    currentAddress.city
                  }
                </div>
                <div>
                  State:{
                    currentAddress.state
                  }
                </div>
                <div>
                  Pincode:{
                    currentAddress.pincode
                  }
                </div>
              </div>
            ) : (
              addresses.length > 0 && (
                <div className="Address-box" style={{ border: '1px solid #bbb' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>Name:{addresses[0].name}</div>
                    <div style={{ color: '#e91e63' }}>
                      <Button onClick={changeAddress}>
                        Change Address
                      </Button>
                    </div>
                  </div>
                  <div>
                    Mobile:{addresses[0].phn}
                  </div>
                  <div>
                    Alt-Mob:{
                      addresses[0].altPhn
                    }
                  </div>
                  <div>
                    Address:{addresses[0].address}
                  </div>
                  <div>
                    City:{
                      addresses[0].city
                    }
                  </div>
                  <div>
                    State:{
                      addresses[0].state
                    }
                  </div>
                  <div>
                    Pincode:{
                      addresses[0].pincode
                    }
                  </div>
                </div>
              )
            )
          }
        </div>
        <div className="cart-btm">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 10px', backgroundColor: '#fff', zIndex: '999' }}>
            <div className="cart-btm-price">&#8377; {netPayable}</div>
            <button className="cart-btm-go" onClick={() => { handleOrderCheckout() }}>Checkout</button>
          </div>
        </div>
      </section >
      <ToastContainer />
    </>
  );
}
