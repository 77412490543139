import React, { useEffect, useState } from 'react';
import {
    EffectFade,
    Navigation,
    Pagination,
    Mousewheel,
    Keyboard,
    Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { IoPerson } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import { Login } from "../../components/pages/Login/Login";
import { SignIn } from "../../components/pages/Login/Signin";
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../redux/athentication/Athentication';

const SlideBanner = () => {
    const { isAuth, loginData,isShowingModal } = useSelector((store) => store.Athentication);
    const { wishlist } = useSelector((store) => store.Wishlist)
    const { CartItems } = useSelector((state) => state.Cart || {});
    const [show, setShow] = useState(false);
    const [shouldDisplay, setShouldDisplay] = useState(false);
    const [cartItem, setCartItem] = useState(0);
    const [showRegister, setShowRegister] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const navigate = useNavigate();
    const dispatch=useDispatch()

    const closeSideMenu = () => {
        setShouldDisplay(false);
    };
    

    useEffect(() => {
        if (
            CartItems !== undefined &&
            Array.isArray(CartItems) &&
            CartItems.length > 0
        ) {
            setCartItem(CartItems.length);
        } else {
            setCartItem(0);
        }
        const handleOutsideClick = (event) => {
            const header = document.getElementById("header");

            if (header && !header.contains(event.target)) {
                closeSideMenu();
            }
        };
        // console.log(cartItem);

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [CartItems]);

    const showCart = () => {
        if (CartItems.length >= 1) {
            navigate("/cart")
        } else {
            alert("Oops!! your cart is empty")
        }
    }
    const openRegister = () => {
        setShowRegister(true);
        setShowLogin(false);
    };
    const openLogin = () => {
        setShowLogin(true);
        setShowRegister(false);
    };
    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    function profileClick() {
        if (isAuth) {
            navigate("/account");
        } else {
            navigate("/login")
        }
    }
    function showWishlist() {
        if (isAuth && wishlist.length >= 1) {
            navigate(`/wishlist`);
        } else if (!isAuth) {
            alert("Pls Login To See Your Wishlist")
        } else if (wishlist.length === 0) {
            alert("Your wishlist is empty")
        }
    }
    const banners = [
        {
            id: 1,
            url: "/asset/images/courier/happy-deliveryman.jpg"
        },
        {
            id: 2,
            url: "/asset/images/courier/man-standing.jpg"
        },
        {
            id: 3,
            url: "/asset/images/courier/delivery-man-wearing-mask.webp"
        },
        {
            id: 3,
            url: "/asset/images/courier/portrait-uniformed-young-man.webp"
        }
    ]
    return (
        <>
            <div className='slideBanner'>
                <div className="header" id="header">
                    <div>
                        <div className="brand">
                            <Link to="/"><img src="/asset/images/logo/vasantcouriers-png.png" alt="vasantcouriers" style={{ height: '100px', paddingTop: '10px ' }} /></Link>
                        </div>
                    </div>
                    <div className="header-rightpanel">
                        <span
                            onClick={() => {
                                profileClick();
                            }}
                            className="p10"
                        >
                            <IoPerson className="profile-icon" />
                        </span>

                        <span className="p10" onClick={() => { showWishlist() }} style={{ position: "relative" }}>
                            <FaRegHeart className="profile-icon" />
                            <span
                                className="badge bg-light text-dark"
                                style={{
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                    fontSize: "8px",
                                    border: "1px solid #000",
                                }}
                            >
                                {wishlist.length}
                            </span>
                        </span>
                        <span className="p10" style={{ position: "relative" }} onClick={() => { showCart() }}>
                            <FaCartShopping className="profile-icon" />
                            <span
                                className="badge bg-light text-dark"
                                style={{
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                    fontSize: "8px",
                                    border: "1px solid #000",
                                }}
                            >
                                {cartItem}
                            </span>
                        </span>

                    </div>
                </div>
                <div>
                    <Modal
                        size="md"
                        show={isShowingModal}
                        onHide={handleClose}
                        backdrop="false"
                        keyboard={false}
                        centered={Boolean}
                    >
                        <Modal.Body>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    onClick={openLogin}
                                    style={{
                                        margin: "10px",
                                        border: "1px solid #ddd",
                                        width: "50%",
                                        textAlign: "center",
                                        padding: "5px 15px",
                                    }}
                                >
                                    Login
                                </div>
                                <div
                                    onClick={openRegister}
                                    style={{
                                        margin: "10px",
                                        border: "1px solid #ddd",
                                        width: "50%",
                                        textAlign: "center",
                                        padding: "5px 15px",
                                    }}
                                >
                                    Register
                                </div>
                            </div>
                            <div>
                                <div>{showRegister && !showLogin ? <SignIn /> : <Login />}</div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* <div className="modal-footer-section" onClick={() => { handleClose() }}><Link to="/" className="tocart text-light">Go to product page</Link></div> */}
                        </Modal.Footer>
                    </Modal>
                </div>
                <Swiper
                    grabCursor={true}
                    navigation={false}
                    pagination={true}
                    mousewheel={false}
                    keyboard={true}
                    draggable={true}
                    effect={"fade"}
                    modules={[
                        EffectFade,
                        Navigation,
                        Pagination,
                        Mousewheel,
                        Keyboard,
                        Autoplay,
                    ]}
                    loop={true}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false,
                        // pauseOnMouseEnter: true,
                    }}
                    breakpoints={{
                        360: {
                            slidesPerView: 1,
                        },
                        460: {
                            slidesPerView: 1,
                        },
                        720: {
                            slidesPerView: 1,
                        },
                        1080: {
                            slidesPerView: 1,
                        },
                    }}
                    className="homeSlider"
                >
                    {
                        banners.map((banner, index) =>
                            <SwiperSlide key={index}>
                                <div className="slider-animate">
                                    <div className="">
                                        <img
                                            src={banner.url}
                                            className=""
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    }
                </Swiper>
            </div>
        </>
    )
}

export default SlideBanner