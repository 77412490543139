import { Link } from "react-router-dom"

export function MainBanner() {
    return (
        <>
            <div className="main-banner">
                <img className="main-banner-img" alt="" src="/asset/images/thumb/pickle_bliss_-_1000x200_copy_3_1.5x.webp"/>
                    {/* <img src="/asset/images/thumb/beer-snacks-plates-fried-dushbara-shrimps-boiled-chickpeas-removebg-preview.png" className="main-banner-img-rightpanel" alt="" /> */}
                {/* <div className="main-banner-text">
                    <div className="heading">Home Style Pickle</div>
                    <p>Made with love</p>
                    <p>With Hygenic...</p>
                    <button className="banner-button" ><Link to="/productbycategory/Nonveg pickle" className="tocategory">Explore Now</Link></button>
                </div> */}
            </div>
        </>
    )
}