import React from 'react'

function BusinessStrategy() {
    return (
        <>
            <div className='how-we-do-box'>
                <div className='how-we-do'>
                    <div className='how-we-do-title'>HOW WE DO</div>
                    <div className='hr-line'></div>
                </div>
                <div className='how-we-do-row row'>
                    <div className="how-we-do-img-col col-lg-6 col-md-6 col-5">
                        <img src="/asset/images/courier/young-delivery-girl-wearing-red-t-shirt-red-cap-wears-face-mask-holding-box.webp" alt="courier-girl" />
                    </div>
                    <div className="how-we-do-text-col col-lg-6 col-md-6 col-7">
                        <div className='how-we-do-text-col-inner'>
                            <div className="how-we-do-text-col-title">PACKING</div>
                            <div className='hr-line'>
                            </div>
                            <p>As we have done a deep research regarding the issues and functions of the</p>
                            <button className='how-we-do-text-col-inner-button'>READ MORE</button>
                        </div>
                    </div>
                </div>
                <div className='how-we-do-row row mt-4'>
                    <div className="how-we-do-text-col col-lg-6 col-md-6 col-7">
                        <div className='how-we-do-text-col-inner'>
                            <div className="how-we-do-text-col-title">TRANSPORTATION</div>
                            <div className='hr-line'>
                            </div>
                            <p>The prime function among all of our functions is transportation.</p>
                            <button>READ MORE</button>
                        </div>
                    </div>
                    <div className="how-we-do-img-col col-lg-6 col-md-6 col-5">
                        <img src="/asset/images/courier/delivery-man-wearing-mask.webp" alt="courier-van" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessStrategy