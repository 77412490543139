import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../redux/athentication/Athentication";
import { Navigate, useNavigate } from "react-router-dom";
import { Addaddress } from "../AddAddress";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useEffect, useState } from "react";
import AccountDetails from "../../AccountDetails";
import { PastOrder } from "../../PastOrders";
import { modalShow } from "../../../redux/address/Address"
import SecondHeader from "../../SecondHeader";
import { ToastContainer, toast } from "react-toastify";
import { SignIn } from "../Login/Signin";
import { Login } from "../Login/Login";

export function Account() {
  const { loginData,isShowingModal } = useSelector((store) => store.Athentication)
  const { clientOrder } = useSelector((store) => store.Order)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [selectedComponent, setSelectedComponent] = useState("accountDetails")
  const [showComponent, setShowComponent] = useState("dashboard")
  const [showRegister, setShowRegister] = useState(true);
  const [showLogin, setShowLogin] = useState(false);


  function handleLogout() {
    dispatch(authActions.signout());
    navigate("/");
  }
  const handleChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].value;
    if(text==="logout"){
      handleLogout()
    }
    setShowComponent(text)
  }
  const handleOrders = () => {
    if (clientOrder.length > 0) {
      setSelectedComponent("pastOrders")
    } else {
      toast.error("You doesn't have any past orders", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        zIndex: 11000,
      });
    }
  }

  const openRegister = () => {
    setShowRegister(true);
    setShowLogin(false);
  };
  const openLogin = () => {
    setShowLogin(true);
    setShowRegister(false);
  };

  const handleClose=()=>{
    dispatch(isShowingModal(false))
  }
  return (
    <>
      <SecondHeader />
      <div className="accounts-page desktop-account">
        <div className="row">
          <div className="col-lg-3 col-md-3 dashboard-section">
            <div>
              <div className="dashboard-title" onClick={() => setSelectedComponent("accountDetails")}>Dashboard</div>
              <div className="dashboard-title" onClick={() => handleOrders()}>Orders</div>
              <div className="dashboard-title" onClick={() => { setSelectedComponent("addressDetails"); dispatch(modalShow(true)) }}>Add Address</div>
              <div className="dashboard-title" onClick={() => {
                handleLogout();
              }}>Logout</div>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 dashboard-content">
            {selectedComponent === "accountDetails" && <AccountDetails />}
            {selectedComponent === "pastOrders" && <PastOrder />}
            {selectedComponent === "addressDetails" && <Addaddress />}
          </div>
        </div>
      </div>
      <div className="mob-account">
        <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
          <select onChange={(e) => handleChange(e)} style={{}}>
            <option value="dashboard">Dashboard</option>
            <option value="orders">Orders</option>
            <option value="address">Add address</option>
            <option value="logout">Logout</option>
          </select>
        </div>
        <div >
          {showComponent === "dashboard" && <AccountDetails />}
          {showComponent === "orders" && <PastOrder />}
          {showComponent === "address" && <Addaddress />}
        </div>
        <Modal
          size="md"
          show={isShowingModal}
          onHide={handleClose}
          backdrop="false"
          keyboard={false}
          centered={Boolean}
        >
          <Modal.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                onClick={openLogin}
                style={{
                  margin: "10px",
                  border: "1px solid #ddd",
                  width: "50%",
                  textAlign: "center",
                  padding: "5px 15px",
                }}
              >
                Login
              </div>
              <div
                onClick={openRegister}
                style={{
                  margin: "10px",
                  border: "1px solid #ddd",
                  width: "50%",
                  textAlign: "center",
                  padding: "5px 15px",
                }}
              >
                Register
              </div>
            </div>
            <div>
              <div>{showRegister && !showLogin ? <SignIn /> : <Login />}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <div className="modal-footer-section" onClick={() => { handleClose() }}><Link to="/" className="tocart text-light">Go to product page</Link></div> */}
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer />
    </>
  );
}
