import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { FaTrashCan } from "react-icons/fa6";
import { FaCartShopping } from "react-icons/fa6";
import { deletewishlistItem, clearWishlistItem, wishlistDelete } from "../redux/wishlist/WishlistSlice"
import { Navigate, useNavigate } from "react-router-dom";
import SecondHeader from "./SecondHeader";

export function WishList() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loginData } = useSelector((store) => store.Athentication);
  const { wishlist } = useSelector((store) => store.Wishlist)

  const deletewishlist = async (data) => {
    const deleteWishlist= await dispatch(wishlistDelete(data))
  }

  useEffect(() => {
    if (wishlist.length === 0) {
      alert("Your wishlist is empty")
      navigate("/")
    }
  }, [wishlist])

  return (
    <>
      <SecondHeader />
      <div className="p100 wishList">
        <div className="wishlist-title">YOUR PRODUCTS WISHLIST</div>
        <div className="row">
          {wishlist.map((data, index) => (
            <div className="col-lg-3 col-md-4 col-6" key={index}>
              <div className="wishlist-inner">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: '5px'
                  }}
                >
                  <img
                    src={data.image}
                    alt=""
                    className="wishlist-img"
                    onClick={() => { navigate(`/productdetail/${data.slugUrl}`) }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: '60px'
                  }}
                >
                  <div>
                    <div className="wishlist-text">{data.productName}</div>
                    <div className="wishlist-text">&#8377;{data.price}</div>
                  </div>
                  <button className="wishlist-btn" onClick={() => { deletewishlist(data) }}>
                    <FaTrashCan />
                  </button>
                  {/* <button className="wishlist-btn" onClick={()=>wishlistAddToCart(data)}>
                    <FaCartShopping />
                  </button> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

    </>
  );
}
