import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  password: "",
  loginData: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData"))
    : "",
  email: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).email
    : "",
  name: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).name
    : "",
  mobile: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).mobile
    : "",
  isAuth: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).isAuth
    : false,
  isShowingModal:false
};

export const adminRegister = createAsyncThunk(
  "admin/adminRegister",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "admin not registered",
    };
    try {
      const config = {
        Headers: {
          "Content-Type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/admin/register`;
      resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getadminbyId = createAsyncThunk(
  "admin/getadminbyId",
  async (id, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/admin/adminById/${id}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("admin Not Found");
    }
  }
);

export const loginAuthentication = createAsyncThunk(
  "authentication/loginAuthentication",
  async (formData) => {
    try {
      const mob = formData.mobile;
      const otp = formData.OTP;

      const url = `https://mobicomm.dove-sms.com/submitsms.jsp?user=VASANTC&key=6dbd5ed439XX&mobile=+91${mob}&message=Dear Customer, Your OTP for logging in to Vasant Couriers is ${otp}. Do not share with anyone. Regards, Team Vasant Couriers NS Lifestyle&senderid=VSNTCR&accusage=1&entityid=1201164562188563646&tempid=1207171056292993567`;
      try {
        const res = await fetch(url);
        res
          .json()
          .then((res) => res)
          .catch((error) => console.log(error));
      } catch (error) {}
    } catch (error) {}
  }
);

export const validatePhoneNumber = createAsyncThunk(
  "admin/validatePhoneNumber",
  async (mobile, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const url = `${Baseurl}/api/v1/admin/mobile/${mobile}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const validateEmail = createAsyncThunk(
  "admin/validateEmail",
  async (email, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Email",
    };
    try {
      const url = `${Baseurl}/api/v1/admin/email/${email}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const adminUpdateById = createAsyncThunk(
  "Athentication/adminUpdateById",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/admin/adminupdate/${formData.userId}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("admin Not Updated");
    }
  }
);

export const clientRegistation = createAsyncThunk(
  "Athentication/clientRegistation",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("client Not create");
    }
  }
);
export const clientLogin = createAsyncThunk(
  "Athentication/clientlogin",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/login`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data,"dsa");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Pls check your information");
    }
  }
);
export const clientUpdate = createAsyncThunk(
  "Athentication/clientUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/Clientid/${formData.claintid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("client Not create");
    }
  }
);

const AthenticationSlice = createSlice({
  name: "Athentication",
  initialState,
  reducers: {
    signIn(state, action) {
      console.log(action.payload, "signIn");
      state.loginData = action.payload;
      state.isAuth = action.payload.isAuth;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.mobile = action.payload.mobile;
      localStorage.setItem("loginData", JSON.stringify(action.payload));
    },
    signout(state) {
      state.loginData = [];
      state.mob = "";
      state.otp = "";
      state.email = "";
      state.name = "";
      state.currentAddress = "";
      state.address = [];
      localStorage.removeItem("loginData");
      localStorage.removeItem("address");
      localStorage.removeItem("currentAddress");
      state.isAuth = false;
    },
    showModal(state,action){
      state.isShowingModal=action.payload
    }
  },
});

export const authActions = AthenticationSlice.actions;
export default AthenticationSlice.reducer;
