import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import SecondHeader from '../SecondHeader';
import { SingleItem } from '../SingleItem';

const OrderSummary = () => {
    const { clientOrder } = useSelector((store) => store.Order);
    const { productTotal } = useSelector((store) => store.Product);
    const [orderDetail, setOrderDetail] = useState("")
    const params = useParams()
    const reversedOrders = clientOrder.slice().reverse();

    useEffect(() => {
        const foundOrder = clientOrder.find(singleOrder => singleOrder.awb === params.awb);
        if (foundOrder) {
            setOrderDetail(foundOrder)
        }
    }, [clientOrder, params.awb])
    return (
        <>
            <div className='desktop-summary'>
                <SecondHeader />
                {
                    orderDetail ? (
                        <>
                            <div className='order-summary-info'>
                                <div className='order-summary-info-title'>Order Summary</div>
                                <div>
                                    <div className='order-summary-info-subtitle'>Shipping Info:</div>
                                    <div className='summary-text'>Name: <span className='summary-point'>{orderDetail.clientname}</span> </div>
                                    <div className='summary-text'>Phone: <span className='summary-point'>{orderDetail.clientPhone}</span></div>
                                    <div className='summary-text'>PaymentInfo:<span className='summary-point'>{orderDetail.paymentInfo.mode}</span></div>
                                    <div className='summary-text'>Address:<span className='summary-point'>{orderDetail.shippingInfo.address},{orderDetail.shippingInfo.city},{orderDetail.shippingInfo.state}</span></div>
                                    <div className='summary-text'>Status:<span className='summary-point'>{orderDetail.statusText}</span></div>
                                    {
                                        orderDetail.statusText === "Order Cancelled" ? (<></>) : (<>
                                            <div className='summary-text'>AWB No:<span className='summary-point'>{orderDetail.awb}</span></div>
                                        </>)
                                    }

                                </div>
                            </div>
                            <div className='order-product-table'>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th className='table-head'>Preview</th>
                                            <th className='table-head'>Product Title</th>
                                            <th className='table-head'>Qty</th>
                                            <th className='table-head'>Price</th>
                                            <th className='table-head'>Sub-total</th>
                                            <th className='table-head'>Shipping Cost</th>
                                            <th className='table-head'>Total Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            orderDetail.orderproducts.map((singleOrder) =>
                                                <tr>
                                                    <td><img src={singleOrder.mblimg} height='50px' width='50px' alt="" /></td>
                                                    <td>{singleOrder.name}</td>
                                                    <td>{singleOrder.Qty}</td>
                                                    <td>{singleOrder.packsize.price}</td>
                                                    <td>{singleOrder.TotalAmount}</td>
                                                    <td>{orderDetail.deliverCharge}</td>
                                                    <td>{orderDetail.netPayable}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='related-product'>
                                <div>related Products</div>
                                <div className='row'>
                                    {
                                        productTotal.map((singleProduct, index) =>
                                            singleProduct.categoryId === orderDetail.orderproducts[0].categoryId ? (<>
                                                <SingleItem key={index} data={singleProduct} />

                                            </>) : (<></>)
                                        )
                                    }
                                </div >
                            </div>
                        </>) : (<></>)
                }
            </div>
            <div className='mob-summary'>
                {
                    orderDetail ? (
                        <>
                            <div className='order-summary-info' style={{ padding: '10px', margin: '10px', }}>
                                <div className='order-summary-info-title'>Order Summary</div>
                                <div>
                                    <div className='order-summary-info-subtitle'>Shipping Info:</div>
                                    <div className='summary-text'>Name: <span className='summary-point'>{orderDetail.clientname}</span> </div>
                                    <div className='summary-text'>Phone: <span className='summary-point'>{orderDetail.clientPhone}</span></div>
                                    <div className='summary-text'>PaymentInfo:<span className='summary-point'>{orderDetail.paymentInfo.mode}</span></div>
                                    <div className='summary-text'>Address:<span className='summary-point'>{orderDetail.shippingInfo.address},{orderDetail.shippingInfo.city},{orderDetail.shippingInfo.state}</span></div>
                                    <div className='summary-text'>AWB No.:<span className='summary-point'>{orderDetail.awb}</span></div>
                                </div>
                            </div>
                            <div className=''>
                                {
                                    reversedOrders.map((detailOrder) => detailOrder.orderproducts.map((singleOrder, index) =>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '5px', boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px', margin: '5px' }} key={index}>
                                            <img src={singleOrder.mblimg} height='100px' width='100px' style={{ margin: '5px' }} alt="" />
                                            <div className="ps-2">
                                                <div className="pastorder-title">Name: <span>{singleOrder.name}</span></div>
                                                <div className="pastorder-title">Qty: <span>{singleOrder.Qty}</span></div>
                                                <div className="pastorder-title">Sub-total: <span>{singleOrder.TotalAmount}</span></div>
                                                <div className="pastorder-title">Delivery Cost: <span>{detailOrder.deliverCharge}</span></div>
                                                <div className="pastorder-title">Grand Total: <span>{detailOrder.netPayable}</span></div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div className='related-product' style={{ padding: '10px', margin: '5px' }}>
                                <div>related Products</div>
                                <div className='row'>
                                    {
                                        productTotal.map((singleProduct, index) =>
                                            singleProduct.categoryId === orderDetail.orderproducts[0].categoryId ? (<>
                                                <SingleItem key={index} data={singleProduct} />

                                            </>) : (<></>)
                                        )
                                    }
                                </div >
                            </div>
                        </>) : (<></>)
                }
            </div>
        </>
    )
}

export default OrderSummary