import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Baseurl } from "../config/BaseUrl"
import { useNavigate } from "react-router-dom";


export function PastOrder() {
    const { clientOrder } = useSelector((store) => store.Order);
    const [modal, setModal] = useState(false);;
    const [orderDetail, setOrderDetail] = useState("");
    const reversedOrders = clientOrder.slice().reverse()

    useEffect(() => {

    }, [])
    const navigate = useNavigate()
    const toggle = (singleOrder) => {
        setModal(!modal);
        setOrderDetail(singleOrder);
    }
    return (
        <>
            <div className="desktop-pastorder">
                <div>recent orders:</div>
                <div className="row">
                    <table class="table">
                        <thead>
                            <tr>
                                <th className='table-head'>Preview</th>
                                <th className='table-head'>Product Title</th>
                                <th className='table-head'>Qty</th>
                                <th className='table-head'>Price</th>
                                <th className='table-head'>Sub-total</th>
                                <th className='table-head'>Shipping Cost</th>
                                <th className='table-head'>Total Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reversedOrders.map((detailOrder) => detailOrder.orderproducts.map((singleOrder, index) =>
                                    <tr key={index} onClick={() => navigate(`/ordersummary/${detailOrder.awb}`)}>
                                        <td>
                                            <img src={singleOrder.mblimg} alt="" height='50px' width='50px' />
                                        </td>
                                        <td>{singleOrder.name}</td>
                                        <td>{singleOrder.Qty}</td>
                                        <td>{singleOrder.packsize.price}</td>
                                        <td>{singleOrder.TotalAmount}</td>
                                        <td>{detailOrder.deliverCharge}</td>
                                        <td>{detailOrder.netPayable}</td>
                                    </tr>
                                )
                                )}

                        </tbody>
                    </table>
                </div>
            </div >
            <div className="mob-pastorder">
                {
                    reversedOrders.map((detailOrder) => detailOrder.orderproducts.map((singleOrder, index) =>
                        <div
                            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '5px', boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px', margin: '5px' }}
                            key={index}
                            onClick={() => navigate(`/ordersummary/${detailOrder.awb}`)}
                        >
                            <img src={singleOrder.mblimg} height='80px' width='80px' style={{ margin: '5px' }} alt="" />
                            <div className="ps-2">
                                <div className="pastorder-title">Name: <span>{singleOrder.name}</span></div>
                                <div className="pastorder-title">Qty: <span>{singleOrder.Qty}</span></div>
                                <div className="pastorder-title">Sub-total: <span>{singleOrder.TotalAmount}</span></div>
                                <div className="pastorder-title">Delivery Cost: <span>{detailOrder.deliverCharge}</span></div>
                                <div className="pastorder-title">Grand Total: <span>{detailOrder.netPayable}</span></div>
                            </div>
                        </div>
                    ))}
            </div>
        </>

    )
}