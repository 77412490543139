import { FaCar } from "react-icons/fa";
import { IoIosHelpBuoy } from "react-icons/io";
import { FaArrowRotateRight } from "react-icons/fa6";
import { MdOutlineScreenLockPortrait } from "react-icons/md";

export function WebFeatures() {
  return (
    <>
      <div className="p100 web-features">
        <div className="web-features-type">
          <FaCar />
          <div>
            <div className="web-features-head">FREE SHIPPING</div>
            <div>Free shipping on all US order or order above $100</div>
          </div>
        </div>
        <div className="web-features-type">
          <IoIosHelpBuoy />
          <div>
            <div className="web-features-head">SUPPORT 24/7</div>
            <div>Contact us 24 hours a day, 7 days a week</div>
          </div>
        </div>
        <div className="web-features-type">
          <FaArrowRotateRight />
          <div>
            <div className="web-features-head">30 DAYS RETURN</div>
            <div>Simply return it within 30 days for an exchange.</div>
          </div>
        </div>
        <div className="web-features-type">
          <MdOutlineScreenLockPortrait />
          <div>
            <div className="web-features-head">100% PAYMENT SECURE</div>
            <div>We ensure secure payment with PEV</div>
          </div>
        </div>
      </div>
    </>
  );
}
