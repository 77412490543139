import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Baseurl } from "../../config/BaseUrl"
import axios from "axios";
import { startTransition } from "react";

const initialState={
    brandTotal:[],
    isLoading:true,
    isbrandLoading:true
}

export const getAllBrand=createAsyncThunk(
    "Brand/getAllBrand",
    async(thunkAPI)=>{
        try{
            const url=`${Baseurl}/api/v1/brand/all`;
            const resp=await axios.get(url);
            return resp.data.brands;
        }catch(error){
            return thunkAPI.rejectWithValue("Brand Not Found")
        }
    }
)

const BrandSlice=createSlice({
    name:"BrandAll",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(getAllBrand.pending,(state)=>{
            state.isLoading=true
        })
        .addCase(getAllBrand.fulfilled,(state,action)=>{
            state.brandTotal=action.payload;
            state.isLoading=false;
            state.isbrandLoading=false
        })
        .addCase(getAllBrand.rejected,(state)=>{
            state.isLoading=true
        })
    }
})

export default BrandSlice.reducer