import { IoIosSearch } from "react-icons/io";
import { IoPerson } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { RxGrid } from "react-icons/rx";
import { IoHomeOutline } from "react-icons/io5";
import { BiCart, BiCategory, BiSolidUser } from "react-icons/bi";
import {
  AiOutlineHome,
  AiOutlineShoppingCart,
  AiOutlineHeart,
} from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { IoArrowForward } from "react-icons/io5";
import { IoMenu } from "react-icons/io5";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Login } from "../components/pages/Login/Login";
import { SignIn } from "../components/pages/Login/Signin";
import { authActions } from "../redux/athentication/Athentication";
// import {cartItem} from"../redux/cart/CartSlice"

export function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [cartItem, setCartItem] = useState(0);
  const [search, setSearch] = useState("");
  const { isAuth, loginData } = useSelector((store) => store.Athentication);
  const { wishlist } = useSelector((store) => store.Wishlist)
  const { CartItems } = useSelector((state) => state.Cart || {});
  const [show, setShow] = useState(false);
  const [showRegister, setShowRegister] = useState(true);
  const [showLogin, setShowLogin] = useState(false);

  const openRegister = () => {
    setShowRegister(true);
    setShowLogin(false);
  };
  const openLogin = () => {
    setShowLogin(true);
    setShowRegister(false);
  };

  const handleClose = () => {
    setShow(false);
  };
  const closeSideMenu = () => {
    setShouldDisplay(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (
      CartItems !== undefined &&
      Array.isArray(CartItems) &&
      CartItems.length > 0
    ) {
      setCartItem(CartItems.length);
    } else {
      setCartItem(0);
    }

    const handleOutsideClick = (event) => {
      const header = document.getElementById("header");

      if (header && !header.contains(event.target)) {
        closeSideMenu();
      }
    };
    // console.log(cartItem);

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [CartItems]);

  function profileClick() {
    if (isAuth) {
      navigate("/account");
    } else {
      toast.error("please login", {
        position: "top-center",
        autoClose: 1000,
      });
      navigate("/login")
    }
  }

  function showWishlist() {
    if (isAuth && wishlist.length >= 1) {
      navigate(`/wishlist`);
    } else if (!isAuth) {
      alert("Pls Login To See Your Wishlist")
    } else if (wishlist.length === 0) {
      alert("Your wishlist is empty")
    }
  }

  const showCart =  () => {
    if (CartItems.length >= 1) {
       navigate("/cart")
    } else {
      alert("Oops!! your cart is empty")
    }
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
    if (e.key === 'Enter') {
      setSearch("")
      navigate(`/searchby/${e.target.value}`);
    }
    console.log(e.key, "e.key");
    console.log(e.target.value, "e.target.value");
  }

  return (
    <>
      {/* <div
        className={`header-mob-menubar  ${shouldDisplay ? "d-block" : "d-none"
          }  d-lg-none`}
        style={{
          transition:
            "opacity 0.3s ease-in-out transform 0.3s ease-in-out, scale 0.3s ease-in-out",
        }}
      >
        <div className="header-mob-menu-inner">
          <div className="header-mob-searchbar">
            <input type="text" placeholder="search product" value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }} />
            <button onClick={() => {
              navigate(`/searchby/${search}`);
            }}>
              <FaSearch />
            </button>
          </div>
          <div
            className="header-mob-title"
            onClick={() => {
              setShouldDisplay(!shouldDisplay);
            }}
          >
            Account
          </div>
          <div
            className="header-mob-title"
            onClick={() => {
              setShouldDisplay(!shouldDisplay);
            }}
          >
            Wishlist
          </div>
          <div
            className="header-mob-title"
          // onClick={() => {
          //   setShouldDisplay(!shouldDisplay);
          // }}
          >
            <Link to="/cart" className="tocart">
              cart
            </Link>
          </div>
        </div>
      </div> */}

      <div>
        <Modal
          size="md"
          show={show}
          onHide={handleClose}
          backdrop="false"
          keyboard={false}
          centered={Boolean}
        >
          <Modal.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                onClick={openLogin}
                style={{
                  margin: "10px",
                  border: "1px solid #ddd",
                  width: "50%",
                  textAlign: "center",
                  padding: "5px 15px",
                }}
              >
                Login
              </div>
              <div
                onClick={openRegister}
                style={{
                  margin: "10px",
                  border: "1px solid #ddd",
                  width: "50%",
                  textAlign: "center",
                  padding: "5px 15px",
                }}
              >
                Register
              </div>
            </div>
            <div>
              <div>{showRegister && !showLogin ? <SignIn /> : <Login />}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <div className="modal-footer-section" onClick={() => { handleClose() }}><Link to="/" className="tocart text-light">Go to product page</Link></div> */}
          </Modal.Footer>
        </Modal>
      </div>

      <div className="Newheader ">
        <div className="  ">
          <form className="mobsearchbox">
            <input type="text" placeholder="Search here..." value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }} />
            <button className="mobsearchbth" onClick={() => {
              navigate(`/searchby/${search}`);
            }}>
              <IoIosSearch size={25} />
            </button>
          </form>
        </div>
      </div>

      <div className="mobileFooter">
        <Link to="/" className="tocart">
          <div className="mb_footdiv">
            <div className="mb_foot_icon">
              <img src="/asset/images/logo/vasantcouriers2.jpg" alt="vasantcouriers" style={{ paddingBottom: '5px' }} />
            </div>

          </div>
        </Link>
        <div className="mb_footdiv" onClick={() => profileClick()}>
          <div className="mb_foot_icon">
            <BiSolidUser size={18} />
          </div>
          <div className="mb_foot_text">
            <h3>Account</h3>
          </div>
        </div>
        <Link to="/" className="tocart">
          <div className="mb_footdiv">
            <div className="mb_foot_icon">
              <IoHomeOutline size={18} />
            </div>
            <div className="mb_foot_text">
              <h3>Home</h3>
            </div>
          </div>
        </Link>
        <div className="mb_footdiv" onClick={() => showWishlist()}>
          <div className="mb_foot_icon">
            <AiOutlineHeart size={18} />
          </div>
          <div className="mb_foot_text">
            <h3>Wish List</h3>
          </div>
        </div>
        {/* </Link> */}
        <div className="tocart" onClick={() => showCart()}>
          <div className="mb_footdiv">
            <div className="mb_foot_icon " style={{ position: "relative" }}>
              <AiOutlineShoppingCart size={18} />
              {CartItems.length > 0 ? (
                <>
                  <span className="cartcount" style={{ fontSize: "10px" }}>
                    {CartItems.length}
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="mb_foot_text">
              <h3>Cart</h3>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
