import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoStar } from "react-icons/io5";
import { IoStarHalf } from "react-icons/io5";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  addtoCart,
  decrementCart,
  getCartTotal,
} from "../redux/cart/CartSlice";
import { WishlistPost } from "../redux/wishlist/WishlistSlice";

export function SingleItem(props) {
  const { isAuth, loginData } = useSelector((store) => store.Athentication);
  const { CartItems } = useSelector((store) => store.Cart)
  const [modalcart, setModalCart] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [qty, setQty] = useState(1)
  const [show, setShow] = useState(false);
  const [selectPack, setSelectPack] = useState(0);
  const [selectPackitem, setSelectPackItem] = useState("");
  const [showMinMaxPrice, setShowMinMaxPrice] = useState("");
  const [showAddToCart, setShowAddToCart] = useState(true);


  
  const handleShow = (data) => {
    const findPrimeIndex = data.packsize.map((item, index) => {
      if (item.prime === true) {
        setSelectPack(index);
        setSelectPackItem(item);
      }
      return item;
    });

    let priceList = [];
    for (let index = 0; index < data.packsize.length; index++) {
      let element = data.packsize[index].price;
      priceList.push(element);
    }

    const minimumPrice = Math.min(...priceList);
    const maxPrice = Math.max(...priceList);
    const price = `₹${minimumPrice}-₹${maxPrice}`;
    setShowMinMaxPrice(price);
    setShow(true);
  };

  const handleClose=()=>{
    setShow(false)
  }

  const handleProduct = () => {
    handleClose()
    navigate(`/productdetail/${props.data.slugUrl}`)
  }

  const AddToCart = (modalProduct) => {
    setShowAddToCart(false)
    setModalCart(!modalcart);
    console.log(modalProduct);
    const formData = {
      _id: modalProduct._id,
      name: modalProduct.name,
      slugUrl: modalProduct.slugUrl,
      metaTitle: modalProduct.metaTitle,
      metaKeyword: modalProduct.metaKeyword,
      metaDesc: modalProduct.metaDesc,
      category: modalProduct.category,
      categoryId: modalProduct.categoryId,
      brand: modalProduct.brand,
      ingredient: modalProduct.ingredient,
      trending: modalProduct.trending,
      offers: modalProduct.offers,
      recommends: modalProduct.recommends,
      productOutOfStock: modalProduct.productOutOfStock,
      mblimg: modalProduct.mblimg,
      productInfo: modalProduct.productInfo,
      manufacturer: modalProduct.manufacturer,
      Qty: 1,
      packsize: {
        price: modalProduct.packsize[selectPack].price,
        packName: modalProduct.packsize[selectPack].packName,
        prime: modalProduct.packsize[selectPack].prime,
      },
      TotalAmount: modalProduct.packsize[selectPack].price * 1,
      TotalPrice: modalProduct.packsize[selectPack].price * 1,
    };
    console.log(formData, "formData");
    dispatch(addtoCart(formData));
    notify();
    handleClose()
  };

  const decreaseFromCart = async (e, showProduct) => {
    try {

    } catch (error) {

    }
    const formData = {
      _id: showProduct._id,
      name: showProduct.name,
      slugUrl: showProduct.slugUrl,
      metaTitle: showProduct.metaTitle,
      metaKeyword: showProduct.metaKeyword,
      metaDesc: showProduct.metaDesc,
      category: showProduct.category,
      categoryId: showProduct.categoryId,
      brand: showProduct.brand,
      ingredient: showProduct.ingredient,
      trending: showProduct.trending,
      offers: showProduct.offers,
      recommends: showProduct.recommends,
      productOutOfStock: showProduct.productOutOfStock,
      mblimg: showProduct.mblimg,
      productInfo: showProduct.productInfo,
      manufacturer: showProduct.manufacturer,
      Qty: 1,
      packsize: {
        price: showProduct.packsize[selectPack].price,
        packName: showProduct.packsize[selectPack].packName,
        prime: showProduct.packsize[selectPack].prime,
      },
      TotalAmount: showProduct.packsize[selectPack].price * 1,
      TotalPrice: showProduct.packsize[selectPack].price * 1,
    };
    console.log(formData, "formData");
    dispatch(decrementCart(formData))
  }


  function notify() {
    toast.success("Product added to cart", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      zIndex: 11000,
    });
  }


  return (
    <>
      <div className="col-lg-2 col-md-4 col-6 pt-2 pb-2 mt-2 single-item-card">
        <div>
          <div>
            <img
              src={props.data.mblimg}
              style={{ width: '100%', height: '159px' }}
              alt=""
              className="singlItem-img"
              onClick={() => {
                navigate(`/productdetail/${props.data.slugUrl}`);
              }}
            />
          </div>
          <div className="searchpage-text">
            <div className="searchpage-review">
              <IoStar className="starfill" />
              <IoStar className="starfill" />
              <IoStar className="starfill" />
              <IoStar className="starfill" />
              <IoStarHalf className="starfill" />
              {/* (41 Reviews) */}
            </div>
            <div className="searchpage-title">{props.data.name}</div>
            <div className="searchpage-subtitle">Made Fresh Every Day</div>
            <div className="searchpage-pricetag">
              {/* &#8377;250.00 - &#8377; 850.00 (Free shipping in india)  */}
            </div>
          </div>
          <div className="searchpage-btn">
            <Button variant="" onClick={() => handleShow(props.data)}>
              Options
            </Button>
          </div>
          <div>
            <Modal
              size="sm"
              show={show}
              onHide={handleClose}
              backdrop="false"
              keyboard={false}
              centered={Boolean}
            >
              <Modal.Body className="modalbody">
                <div
                  style={{
                    color: "#1d2d40",
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                >
                  {props.data.name}
                </div>
                <div
                  className=""
                  style={{ color: "#c82121", fontWeight: "600" }}
                >
                  {showMinMaxPrice}
                  <span style={{ fontSize: "12px" }}>
                    (Inclusive Of Tax & Free Shipping in India)
                  </span>
                </div>
                <div
                  className="pt-1 pb-1"
                  style={{
                    color: "#1d2d40",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  weight:{selectPackitem.packName}
                </div>
                <div
                  className="pb-1 pt-1"
                  style={{ display: "flex", gap: "10px" }}
                >
                  {props.data.packsize.map((pack, index) => (
                    <div
                      onClick={() => {
                        setSelectPack(index);
                        setSelectPackItem(pack);
                      }}
                      className={
                        selectPack === index
                          ? "modal-pricebox-1"
                          : "modal-pricebox"
                      }
                      key={index}
                      style={{ cursor: "pointer" }}
                    >
                      {pack.packName}
                    </div>
                  ))}
                </div>
                <div className="pt-2 pb-1">Price:{selectPackitem.price}</div>
                {props.data.productOutOfStock === false ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <div style={{ color: "#c82121" }} className="pb-3">
                      Out of stock
                    </div>
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="modalcount-qty" >
                    <div
                      onClick={() => {
                        if (qty > 1) {
                          decreaseFromCart(props.data)
                        } else {
                          setShowAddToCart(true)
                        }
                      }}
                    >
                      -
                    </div>
                    <div>{qty}</div>
                    <div
                      onClick={() => {
                        AddToCart(props.data);
                      }}
                    >
                      +
                    </div>
                </div> */}
                </div>
              </Modal.Body>
              <Modal.Footer className="modalfooter">
                <button
                  className="modal-btn"
                  onClick={() => AddToCart(props.data)}
                >
                  Add to cart
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div >
    </>
  );
}
