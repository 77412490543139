import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  addresses: localStorage.getItem("address")
    ? JSON.parse(localStorage.getItem("address"))
    : [],
  currentAddress: localStorage.getItem("currAddress")
    ? JSON.parse(localStorage.getItem("currAddress"))
    : "",
  addressLoading: true,
  isAddressLoading: true,
  isModalShowing: false,
};

export const addressSetup = createAsyncThunk(
  "address/addressSetup",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "address not registered",
    };
    try {
      const config = {
        Headers: {
          "Content-Type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/address/new`;
      resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Order didn't succesfull");
    }
  }
);

const AddressSlice = createSlice({
  name: "Address",
  initialState,
  reducers: {
    addAddress(state, action) {
      console.log(action.payload.payload.address, "action");
      state.addresses = action.payload.payload.address;
      if (state.addresses.length >= 1 && state.currentAddress === "") {
        state.currentAddress = state.addresses[0];
      }
      localStorage.setItem("address", JSON.stringify(state.addresses));
      localStorage.setItem(
        "currentAddress",
        JSON.stringify(state.currentAddress)
      );
    },
    currAddress(state, action) {
      state.currentAddress = action.payload;
      localStorage.setItem(
        "currentAddress",
        JSON.stringify(state.currentAddress)
      );
    },
    updateAddress(state, action) {
      state.addresses = [action.payload, ...state.addresses];
      state.currentAddress = action.payload;

      localStorage.setItem("address", JSON.stringify(state.addresses));
      localStorage.setItem(
        "currentAddress",
        JSON.stringify(state.currentAddress)
      );
    },
    modalShow(state, action) {
      state.isModalShowing = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addressSetup.pending, (state) => {
        state.isAddressLoading = true;
      })
      .addCase(addressSetup.fulfilled, (state, action) => {
        // state.isAddressLoading = false;
        // state.addresses = [action.payload, ...state.addresses];
        // state.currentAddress = action.payload
        // console.log(state.currentAddress, "currentAddress");
        // console.log(state.addresses, "addresses");
      })
      .addCase(addressSetup.rejected, (state) => {
        state.isAddressLoading = true;
      });
  },
});

export const { addAddress, currAddress, updateAddress,modalShow } = AddressSlice.actions;
export const addressActions = AddressSlice.actions;
export default AddressSlice.reducer;
