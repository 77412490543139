import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { Axios } from "axios";
import { Baseurl } from "../../config/BaseUrl";
import { json } from "react-router-dom";
import { Action } from "@remix-run/router";

const initialState = {
  categorytotal:[],
    iscategoryLoading:true,
};

export const getCategory=createAsyncThunk(
    "category/getCategory",
    async(thunkAPI)=>{
        try{
            const url=`${Baseurl}/api/v1/category/all`;
            const resp=await axios(url);
            return resp.data.categories;
        }catch(error){
            return thunkAPI.rejectWithValue("404 not found")
        }
    }
)

const categorySlice=createSlice({
    name:"categoryAll",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(getCategory.pending,(state)=>{
            state.iscategoryLoading=true;
        })
        .addCase(getCategory.fulfilled,(state,action)=>{
            state.categorytotal=action.payload;
            state.iscategoryLoading=false;
            localStorage.setItem("categoryTotal",JSON.stringify(state.categorytotal))
        })
        .addCase(getCategory.rejected,(state)=>{
            state.iscategoryLoading=true
        })
    }
})
export default categorySlice.reducer;
