import { createSlice ,createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState={
    courierPriceList:[],
    isLoading:false
}

export const getCourierPrice=createAsyncThunk(
    "courier/courierPrice",
    async(thunkAPI)=>{
        try{
            const url= `${Baseurl}/api/v1/courierprice/all`;
            const resp=await axios.get(url);
            return resp.data
        }catch(error){
            thunkAPI.rejectWithValue("Courier price not able to get")
        }
    }
)
const courierPriceSlice=createSlice({
    name:"courierPrice",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(getCourierPrice.pending,(state)=>{
            state.isLoading=true
        })
        .addCase(getCourierPrice.fulfilled,(state,action)=>{
            state.courierPriceList=action.payload.courierPrices
            state.isLoading=false
        })
        .addCase(getCourierPrice.rejected,(state)=>{
            state.isLoading=true
        })
    }
})

export default courierPriceSlice.reducer