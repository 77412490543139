import React, { useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { BiPlus, BiMinus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import { IoMdArrowBack } from "react-icons/io";
import {
  removefromCart,
  decrementCart,
  addtoCart,
  getCartTotal,
  clearCart
} from "../../../redux/cart/CartSlice";
import SecondHeader from "../../SecondHeader";
import { NavItem } from "reactstrap";

const Cart = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { CartItems, cartTotalAmount, netPayable } = useSelector(
    (state) => state.Cart
  );
  const { isAuth } = useSelector((store) => store.Athentication);
  const removeCart = (cartItem) => {
    dispatch(removefromCart(cartItem));
    dispatch(getCartTotal())
  };

  const handleDecreaseCart = (cartItem) => {
    dispatch(decrementCart(cartItem));
    dispatch(getCartTotal());
  }

  const handleIncreaseCart = (cartItem) => {
    dispatch(addtoCart(cartItem));
    dispatch(getCartTotal());
  }

  const checkoutClick = () => {
    // alert("asdfg")
    if (CartItems.length >= 1 && isAuth) {
      navigate("/checkout")
    } else if (CartItems.length === 0 && isAuth) {
      alert("Please add some products to your cart")
    } else if (!isAuth) {
      alert("Pls login for checkout")
      navigate("/login")
    }
  }
  useEffect(() => {
    if (CartItems.length === 0) {
      alert("Your cart is empty")
      navigate("/")
    }
  }, [CartItems])

  return (
    <>
      <main className="main dstversion dstversion-cart cart-top">
        <SecondHeader />
        <div className="page-header breadcrumb-wrap cart-lg-top lg-breadcrumb">
          <div className="container">
            <div className="breadcrumb">
              <a rel="nofollow" onClick={() => navigate("/")}>
                <IoMdArrowBack
                  size={20}
                  className="mx-1"
                  style={{ margin: "0%" }}
                />
              </a>
              <span /> cart
            </div>
          </div>
        </div>
        <div className=" mb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 ">
                <div className="table-responsive">
                  <table className="table shopping-summery text-center clean">
                    <thead>
                      <tr className="main-heading">
                        <th scope="col">Image</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Price</th>
                        <th scope="col">Subtotal</th>
                        <th scope="col">Remove</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {CartItems.map((cartItem, index) => (
                        <tr key={index}>
                          <td>
                            <img
                              src={cartItem.mblimg}
                              className="cart-image"
                              alt="product-image"
                              style={{ height: "50px", width: "50px" }}
                              onClick={() => {
                                navigate(`/productdetail/${cartItem.slugUrl}`);
                              }}
                            />
                          </td>
                          <td>
                            <div>{cartItem.name}</div>
                          </td>
                          <td>
                            <div className="product-count">
                              <button
                                style={{ backgroundColor: 'unset', border: 'none', outline: 'none' }}
                                type="button"
                                onClick={() => {
                                  if (cartItem.Qty > 0) {
                                    handleDecreaseCart(cartItem)
                                  }
                                }}
                              >
                                -
                              </button>
                              <input
                                // className="form-control middlepart"
                                type="text"
                                name="quantity"
                                defaultValue={0}
                                value={cartItem.Qty}
                                contentEditable="false"
                                style={{ width: '50px', border: 'none', textAlign: 'center' }}
                              />
                              <button
                                style={{ backgroundColor: 'unset', border: 'none', outline: 'none' }}
                                type="button"
                                onClick={() => {
                                  handleIncreaseCart(cartItem)
                                }}
                              >
                                +
                              </button>
                            </div>
                          </td>
                          <td>
                            <div>&#8377; {cartItem.packsize.price}</div>
                          </td>
                          <td>
                            <div>&#8377; {cartItem.TotalAmount}</div>
                          </td>
                          <td
                            onClick={() => {
                              removeCart(cartItem)
                            }}
                          >
                            <FaTrashAlt />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className="cart-action text-end ">
                  <button className="btn  mr-10 mb-sm-15 " onClick={() => { dispatch(clearCart()) }} style={{ backgroundColor: '#fa7f1b', margin: '3px', color: '#fff' }}>
                    <i className="fi-rs-cross-small mr-10" />
                    Clear Cart
                  </button>
                  <button className="btn" style={{ backgroundColor: '#fa7f1b', margin: '3px', color: '#fff' }} onClick={() => navigate("/shop")}>
                    <i className="fi-rs-shopping-bag mr-10" />
                    Continue Shopping
                  </button>
                </div>
                <div className="divider center_icon mt-">
                  <i className="fi-rs-fingerprint" />
                </div>
                <div className=" mb-50">
                  <div className="">
                    <div className="border p-md-4 p-30 border-radius cart-totals">
                      <div className="heading_s1 mb-3">
                        <h4>Cart Totals</h4>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td className="cart_total_label">
                                Cart Subtotal
                              </td>
                              <td className="cart_total_amount">
                                <span className="font-lg fw-900 text-brand">
                                  {cartTotalAmount}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td className="cart_total_label">Total</td>
                              <td className="cart_total_amount">
                                <strong>
                                  <span className="font-xl fw-900 text-brand">
                                    {netPayable}
                                  </span>
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div style={{ fontSize: '12px' }}>We will inform you about the shipping cost shortly&nbsp;Incase of need call <span style={{ color: '#25d366', fontWeight: '600' }}>+91 9391445005</span>
                          <a href="//api.whatsapp.com/send?phone=919391445005" className="ps-3">
                            <img src="/asset/images/banner/4017334_circle_logo_media_network_social_icon.png" alt="whatsapp logo" height='30px' width='30px' />
                          </a>
                        </div>
                      </div>
                      <button style={{ backgroundColor: '#0086ca', padding: '10px', borderRadius: '8px', border: 'none', color: '#fff', margin: '30px 0px', }} onClick={() => { checkoutClick() }}>
                        <i className="fi-rs-box-alt mr-10" />
                        Proceed To
                        CheckOut
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main >
      <section className="mob-cart">
        <div className="container">
          <div className="breadcrumb">
            <a rel="nofollow" onClick={() => navigate("/")}>
              <IoMdArrowBack
                size={20}
                className="mx-1"
                style={{ margin: "0%" }}
              />
            </a>
            <span /> cart
          </div>
        </div>
        {
          CartItems.map((individualItem, index) =>
            <div className="individual-item row">
              <div className="col-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={individualItem.mblimg} alt="" className="cart-img" />
              </div>
              <div className="col-8 p-0">
                <div className="pastorder-title">name: <span className="ps-2">{individualItem.name}</span></div>
                <div className="pastorder-title"> price: <span className="ps-2">&#8377; {individualItem.packsize.price}</span> </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div className="pastorder-title" style={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center', gap: '5px' }}>
                    Qty:
                    <span className="product-count-mob ps-2">
                      <button
                        style={{ backgroundColor: 'unset', border: 'none', outline: 'none' }}
                        type="button"
                        onClick={() => {
                          if (individualItem.Qty > 0) {
                            handleDecreaseCart(individualItem)
                          }
                        }}
                      >
                        -
                      </button>
                      <input
                        // className="form-control middlepart"
                        type="text"
                        name="quantity"
                        defaultValue={0}
                        value={individualItem.Qty}
                        contentEditable="false"
                        style={{ width: '50px', border: 'none', textAlign: 'center' }}
                      />
                      <button
                        style={{ backgroundColor: 'unset', border: 'none', outline: 'none' }}
                        type="button"
                        onClick={() => {
                          handleIncreaseCart(individualItem)
                        }}
                      >
                        +
                      </button>
                    </span>
                  </div>
                  <div>
                    <div className="pe-3 "
                      onClick={() => {
                        removeCart(individualItem)
                      }}>
                      <BsTrashFill />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div className="cart-btm-top">
          <div className="cart-action text-end ">
            <button className="btn  mr-10 mb-sm-15 " onClick={() => { dispatch(clearCart()) }} style={{ backgroundColor: '#fa7f1b', margin: '3px', color: '#fff' }}>
              <i className="fi-rs-cross-small mr-10" />
              Clear Cart
            </button>
            <button className="btn" style={{ backgroundColor: '#fa7f1b', margin: '3px', color: '#fff' }}>
              <i className="fi-rs-shopping-bag mr-10" />
              Continue Shopping
            </button>
          </div>
          <div className="divider center_icon mt-">
            <i className="fi-rs-fingerprint" />
          </div>
          <div className="total-cart">
            <div className="total-cart-design">Price Details ({CartItems.length} Items)</div>
            <div >
              <div className="total-cart-arg">
                <div style={{ fontWeight: '600', color: '#555' }}>
                  Total Product Price:
                </div>
                <div style={{ color: 'green' }}>
                  {cartTotalAmount}
                </div>
              </div>
              <div className="total-cart-arg" style={{ borderTop: '1px solid #bbb', paddingTop: '5px' }}>
                <div style={{ fontWeight: '600', color: '#555' }}>
                  Order Total:
                </div>
                <div className="net-payable" style={{ color: 'green' }}>
                  {netPayable}
                </div>
              </div>
            </div>
            <p className="cartdelivery">
              We will inform you about the shipping cost shortly
              Incase of need call <span style={{ color: '#25d366', fontWeight: '600' }}> +91 9391445005</span>
              <a href="//api.whatsapp.com/send?phone=919391445005" className="ps-3">
                <img src="/asset/images/banner/4017334_circle_logo_media_network_social_icon.png" alt="whatsapp logo" height='30px' width='30px' />
              </a>
            </p>
            <div className="cart-btm">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 10px' }}>
                <div className="cart-btm-price">&#8377; {netPayable}</div>
                <button className="cart-btm-go" onClick={() => { checkoutClick() }}>Continue</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
