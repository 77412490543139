import { IoIosStar } from "react-icons/io";
import { FaRegHeart } from "react-icons/fa";
import { IoChevronForward } from "react-icons/io5";
// import { FaRegHeart } from "react-icons/fa";
import { IoMdShuffle } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BeatLoader from "react-spinners/BeatLoader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoStar } from "react-icons/io5";
import { IoStarHalf } from "react-icons/io5";
import { WishlistPost } from "../../../redux/wishlist/WishlistSlice";
import { SingleItem } from "../../SingleItem";
import {
  addtoCart,
  decrementCart,
  getCartTotal,
} from "../../../redux/cart/CartSlice";
import SecondHeader from "../../SecondHeader";

export function ProductDetail() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true)
  const [showProduct, setShowProduct] = useState("");
  const [showIndex, setShowIndex] = useState("");
  const [selectPack, setSelectPack] = useState(0);
  const [show, setShow] = useState(false);
  let [qty, setQty] = useState(1)
  const [selectPackitem, setSelectPackItem] = useState("")
  const { productTotal, recommendedTotal } = useSelector(
    (store) => store.Product
  );
  const { wishlist } = useSelector((store) => store.Wishlist)
  const { isAuth, loginData } = useSelector((store) => store.Athentication);
  const { CartItems } = useSelector((store) => store.Cart)
  const [showAddToCart, setShowAddToCart] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);

  };
  useEffect(() => {
    const singleProduct = params.productUrl;
    let currentProduct = productTotal.find(
      (product) => product.slugUrl === singleProduct
    );
    setShowProduct(currentProduct);
    const findPrimeIndex = currentProduct.packsize.map((item, index) => {
      if (item.prime === true) {
        setSelectPack(index)
        setSelectPackItem(item)
      }
      return item
    })


    let cartPackQty = []
    for (let index = 0; index < currentProduct.packsize.length; index++) {
      let packQnty = {}
      let element = currentProduct.packsize[index];
      packQnty = {
        packId: element._id,
        CartQty: 0,
        prime: element.prime
      };
      cartPackQty.push(packQnty)
      cartPackQty = [...cartPackQty]
      currentProduct = { ...currentProduct, cartPackQty }
    }
    CartItems.map((cartitem) => {
      let itemIndex = false;
      itemIndex = String(currentProduct._id) === String(cartitem._id)
      if (itemIndex) {
        let packIndex = -1;

        packIndex = currentProduct.cartPackQty.findIndex((packQnty) =>
          packQnty.prime === cartitem.packsize.prime
        )
        if (packIndex >= 0) {
          currentProduct.cartPackQty[packIndex].CartQty = 1;
        }
        setShowProduct(currentProduct)
      }

    })

    window.scrollTo(0, 0)
  }, [params.productUrl, CartItems]);

  const addTocartClick = (e) => {
    setShowAddToCart(false)
    const formData = {
      _id: showProduct._id,
      name: showProduct.name,
      slugUrl: showProduct.slugUrl,
      metaTitle: showProduct.metaTitle,
      metaKeyword: showProduct.metaKeyword,
      metaDesc: showProduct.metaDesc,
      category: showProduct.category,
      categoryId: showProduct.categoryId,
      brand: showProduct.brand,
      ingredient: showProduct.ingredient,
      trending: showProduct.trending,
      offers: showProduct.offers,
      recommends: showProduct.recommends,
      productOutOfStock: showProduct.productOutOfStock,
      mblimg: showProduct.mblimg,
      productInfo: showProduct.productInfo,
      manufacturer: showProduct.manufacturer,
      Qty: 1,
      packsize: {
        price: showProduct.packsize[selectPack].price,
        packName: showProduct.packsize[selectPack].packName,
        prime: showProduct.packsize[selectPack].prime,
      },
      TotalAmount: showProduct.packsize[selectPack].price * 1,
      TotalPrice: showProduct.packsize[selectPack].price * 1,
    };
    dispatch(addtoCart(formData));
  };

  function notify() {
    toast.success("Product added to cart", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      zIndex: 11000,
    });
  }

  const decreaseFromCart = async (e, showProduct) => {
    try {

    } catch (error) {

    }
    const formData = {
      _id: showProduct._id,
      name: showProduct.name,
      slugUrl: showProduct.slugUrl,
      metaTitle: showProduct.metaTitle,
      metaKeyword: showProduct.metaKeyword,
      metaDesc: showProduct.metaDesc,
      category: showProduct.category,
      categoryId: showProduct.categoryId,
      brand: showProduct.brand,
      ingredient: showProduct.ingredient,
      trending: showProduct.trending,
      offers: showProduct.offers,
      recommends: showProduct.recommends,
      productOutOfStock: showProduct.productOutOfStock,
      mblimg: showProduct.mblimg,
      productInfo: showProduct.productInfo,
      manufacturer: showProduct.manufacturer,
      Qty: 1,
      packsize: {
        price: showProduct.packsize[selectPack].price,
        packName: showProduct.packsize[selectPack].packName,
        prime: showProduct.packsize[selectPack].prime,
      },
      TotalAmount: showProduct.packsize[selectPack].price * 1,
      TotalPrice: showProduct.packsize[selectPack].price * 1,
    };
    console.log(formData, "formData");
    dispatch(decrementCart(formData))
  }

  const addToWishlist = (product) => {
    if (isAuth) {
      if (wishlist.some((singleWishlist) =>
        singleWishlist._id === product._id
      ) && wishlist.length === 0) {
        alert(`${product.name} is already in the wishlist`)
      } else {
        const formData = {
          clientId: loginData._id,
          category: product.category,
          categoryId: product.categoryId,
          slugUrl: product.slugUrl,
          productid: product._id,
          productName: product.name,
          price: product.packsize[0].price,
          image: product.mblimg,
          brand: product.brand,
        };
        dispatch(WishlistPost(formData));
        alert(`${product.name} has been added to wishlist`);
      }

    }
  }
  // console.log(selectPack,"selectPack");
  // console.log(showProduct.cartPackQty[selectPack],"btn");


  return (
    // <>
    //   {
    //     showProduct ?
    //       (
    //         <>
    //           <div style={{ height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             <h4 style={{ fontWeight: "bold", color: "#555" }}>
    //               Product is being fetched
    //             </h4>
    //             <BeatLoader
    //               color={"#555"}
    //               loading={loading}
    //               size={10}
    //               className="mt-2 mx-2"
    //             />
    //           </div>
    //         </>
    //       ) :
    //       (
    //         <>
    //           <div className="bread-crumb">
    //             <div>
    //               Home<IoChevronForward />
    //               Products <IoChevronForward />
    //               {showProduct.category} <IoChevronForward />
    //               {showProduct.name} <IoChevronForward />
    //             </div >
    //           </div >
    //           <div className="p100 row product-detail">
    //             <div className="col-lg-6 col-sm-6 col-xs-6 product-detail-leftpanel">
    //               <img src={showProduct.mblimg} className="product-image" alt="" />
    //             </div>
    //             <div className="col-lg-6 col-sm-6 col-xs-6">
    //               <h3>{showProduct.name}</h3>
    //               <div>
    //                 <div className="product-review">
    //                   <IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStar />
    //                   <IoIosStar />
    //                 </div>
    //                 <div>(19 Customer Reviews)</div>
    //                 <div className="product-price">
    //                   ₹242.00 – ₹750.00 (Inclusive Of Tax & Free Shipping in India)
    //                 </div>
    //                 <div>weight: {selectPackitem.packName}</div>
    //                 <div className="product-flex-between">
    //                   {showProduct !== "" ? <>  {showProduct.packsize.map((pack, index) => (
    //                     <div
    //                       onClick={() => {
    //                         setSelectPack(index);
    //                         setSelectPackItem(pack);
    //                       }}
    //                       className={
    //                         selectPack === index
    //                           ? "modal-pricebox-1"
    //                           : "modal-pricebox"
    //                       }
    //                       key={index}
    //                       style={{ cursor: "pointer" }}
    //                     >
    //                       {pack.packName}
    //                     </div>
    //                   ))}</> : <></>}



    //                 </div>
    //                 <div className="product-price">
    //                   ₹{selectPackitem.price} (Inclusive Of Tax & Free Shipping in India)
    //                 </div>
    //                 <div className="product-flex-between">

    //                   <div className="product-count">
    //                     <div
    //                       onClick={(e) => {
    //                         decreaseFromCart(e, showProduct)
    //                       }}
    //                     >-</div>
    //                     <div>{qty}</div>
    //                     <div
    //                       onClick={(e) => {
    //                         addTocartClick(e);
    //                         notify();
    //                       }}
    //                     >+</div>
    //                   </div>
    //                   <button
    //                     className="product-addbtn"
    //                     onClick={
    //                       (e) => {
    //                         addTocartClick(e);
    //                         notify();
    //                       }
    //                     }
    //                   >
    //                     Add to cart
    //                   </button>
    //                   <div className="product-addwish" onClick={() => { addToWishlist(showProduct) }}>
    //                     <FaRegHeart />
    //                   </div>
    //                 </div>
    //                 <p>
    //                   Best of the best you can get to make the best tasting breakfast
    //                   that your family loves to have in every bite.
    //                 </p>
    //               </div>
    //             </div>
    //           </div >
    //           <div className="p100 product-decription">
    //             <div>
    //               <div className="product-decription-title">
    //                 Introducing Sitara Foods’ Latest Creation: Kadapa Dosa Karam Instant
    //                 Mix! (Wonderfully Delicious)
    //               </div>
    //               <p>
    //                 Are you tired of the same old breakfast routine and craving
    //                 something exciting and spicy? Look no further! Sitara Foods proudly
    //                 presents our all-new Kadapa Dosa Karam Instant Mix, designed to
    //                 bring a burst of flavor to your morning meals and add a touch of
    //                 South Indian magic to your culinary adventures.
    //               </p>
    //               <h4>Why Kadapa Dosa Karam?</h4>
    //               <p>
    //                 <strong>Make Flavorful Karam Dosa in Minutes:</strong>
    //                 Our Kadapa Dosa Karam Instant Mix is the ultimate solution for busy
    //                 mornings when time is of the essence. With this instant mix, you can
    //                 effortlessly whip up deliciously spicy Karam Dosas without
    //                 compromising on taste or authenticity. Just a few simple steps, and
    //                 you’re on your way to a delightful breakfast experience.
    //               </p>
    //               <p>
    //                 <strong>Perfectly Pairs with Soft, Fluffy Idly:</strong> Looking for
    //                 the perfect companion to your fluffy Idlys? The Kadapa Dosa Karam is
    //                 the answer! This versatile mix not only elevates your dosa but also
    //                 complements your soft Idlys, creating a symphony of flavors that
    //                 will leave your taste buds craving for more.
    //               </p>
    //               <p>
    //                 <strong>Easy to Use –</strong>
    //                 Just Mix It with Water and Enjoy: We understand the importance of
    //                 convenience in your busy schedule. That’s why our Kadapa Dosa Karam
    //                 Instant Mix is designed for simplicity. Just take a scoop of the
    //                 mix, add water, give it a quick mix, and voilà! Your spicy dosa
    //                 karam is ready to tantalize your palate.
    //               </p>
    //               <p>
    //                 <strong>Taste of Home:</strong>
    //                 Handcrafted with Love: At Sitara Foods, we believe that the best
    //                 flavors come from the heart. Our Kadapa Dosa Karam is lovingly
    //                 handcrafted using the finest ingredients, maintaining the authentic
    //                 taste of home-cooked goodness in every bite. It’s like a warm
    //                 embrace from your grandmother’s kitchen.
    //               </p>
    //             </div>
    //           </div>
    //           <div className="p100 ">
    //             <div className="">
    //               <div className="title">Recommended Products</div>
    //             </div>
    //             <div className="row">
    //               {recommendedTotal.map((data, index1) => {
    //                 return <SingleItem data={data} key={index1} />
    //               }
    //               )}
    //             </div>
    //           </div>
    //           <ToastContainer />
    //         </>
    //       )
    //   }
    // </>
    <>
      <SecondHeader />
      <div className="bread-crumb">
        <div>
          Home<IoChevronForward />
          Products <IoChevronForward />
          {showProduct.category} <IoChevronForward />
          {showProduct.name} <IoChevronForward />
        </div >
      </div >
      <div className="p100 row product-detail">
        <div className="col-lg-6 col-sm-6 col-xs-6 product-detail-leftpanel">
          <img src={showProduct.mblimg} className="product-image" alt="" />
        </div>
        <div className="col-lg-6 col-sm-6 col-xs-6">
          <h3>{showProduct.name}</h3>
          <div>
            <div className="product-review">
              <IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStar />
              <IoIosStar />
            </div>
            {/* <div>(19 Customer Reviews)</div> */}
            <div className="product-price">
              {/* ₹242.00 – ₹750.00 (Inclusive Of Tax & Free Shipping in India) */}
            </div>
            <div>weight: {selectPackitem.packName}</div>
            <div className="product-flex-between">
              {showProduct !== "" ? <>  {showProduct.packsize.map((pack, index) => (
                <div
                  onClick={() => {
                    setSelectPack(index);
                    setSelectPackItem(pack);
                  }}
                  className={
                    selectPack === index
                      ? "modal-pricebox-1"
                      : "modal-pricebox"
                  }
                  key={index}
                  style={{ cursor: "pointer" }}
                >
                  {pack.packName}
                </div>
              ))}</> : <></>}



            </div>
            <div className="product-price">
              ₹{selectPackitem.price}
            </div>
            <div className="product-flex-between">
              {
                showProduct.cartPackQty ? (

                  <> {
                    showProduct.cartPackQty[selectPack].CartQty > 0 ?
                      (
                        <div className="product-count">
                          <div
                            onClick={(e) => {
                              decreaseFromCart(e, showProduct)
                            }}
                          >-</div>
                          <div>{qty}</div>
                          <div
                            onClick={(e) => {
                              addTocartClick(e);
                              notify();
                            }}
                          >+</div>
                        </div>
                      ) :
                      (
                        <button
                          className="product-addbtn"
                          onClick={
                            (e) => {
                              addTocartClick(e);
                              notify();
                            }
                          }
                        >
                          Add to cart
                        </button>
                      )
                  }</>

                ) : (
                  <button
                    className="product-addbtn"
                    onClick={
                      (e) => {
                        addTocartClick(e);
                        notify();
                      }
                    }
                  >
                    Add to cart
                  </button>
                )


              }
              <div className="product-addwish" onClick={() => { addToWishlist(showProduct) }}>
                <FaRegHeart />
              </div>
            </div>
            <p>
              Ingredient: <span style={{ fontSize: '12px' }}>{showProduct.ingredient}</span>
            </p>
          </div>
        </div>
      </div >
      <div className="p100 product-decription">
        <div ><span style={{ fontWeight: '600' }}> Product info:</span>
          {showProduct.productInfo}
        </div>
      </div>
      <div className="p100 ">
        <div className="">
          <div className="title">Recommended Products</div>
        </div>
        <div className="row">
          {recommendedTotal.map((data, index1) => {
            return <SingleItem data={data} key={index1} />
          }
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
