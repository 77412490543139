import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FaArrowRightLong } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Baseurl } from '../../config/BaseUrl';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';

const ShopNow = () => {
    const { categorytotal } = useSelector((store) => store.Category);
    const [show, setShow] = useState(false);
    const [trackingData, setTrackingData] = useState("")
    const [trackingNo, setTrackingNo] = useState("")

    const handleCategory = (category) => {
        navigate(`/productbycategory/${category.catname}`)
    }

    const handleTracking = async (e) => {
        const trackId = e.target.value;
        const url = `${Baseurl}/api/v1/courier/courierdetail/${trackingNo}`
        const resp = await axios.get(url);
        if (resp.data.success) {
            setTrackingData(resp.data.courier)
            setShow(true)
            setTrackingNo("")
        } else {
            toast.error("Enter valid tracking no.", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                zIndex: 11000,
            });
        }
    }
    const navigate = useNavigate()
    return (
        <>
            <div className='padding-50 row align-items-center'>
                <div className="col-lg-2 col-sm-4 col-6 p-2">
                    <div className='shipment-trackbox'>
                        <div className='shipment-text'>
                            Track Your Shipment
                        </div>
                        <div>
                            <input type="text" placeholder='Enter tracking no.' onChange={(e) => setTrackingNo(e.target.value)} value={trackingNo} />
                        </div>
                        <div>
                            <button onClick={(e) => handleTracking(e)}>Track</button>
                        </div>
                    </div>
                    <Modal
                        size="md"
                        show={show}
                        onHide={() => setShow(false)}
                        backdrop="false"
                        keyboard={false}
                        centered={Boolean}
                    >
                        <Modal.Body >
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td>AWB No.</td>
                                        <td>{trackingData.awb}</td>
                                    </tr>
                                    <tr>
                                        <td>Booking Date</td>
                                        <td>{trackingData.bookingDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Consignee</td>
                                        <td>{trackingData.consignee}</td>
                                    </tr>
                                    <tr>
                                        <td>Mobile</td>
                                        <td>{trackingData.mobile}</td>
                                    </tr>
                                    <tr>
                                        <td>Forwarding No.</td>
                                        {
                                            trackingData.forwarding ? (
                                                <>
                                                    <td
                                                        onClick={() => window.open(`https://m.17track.net/en/track-details?nums=${trackingData.forwarding}`, '_blank')}
                                                    ><span className='ms-2' style={{ fontSize: '10px' }}>(Click to track)</span></td>
                                                </>) : (
                                                <>
                                                    <td>
                                                        <div>Forwarding No. will  be generated within 24 hours </div>
                                                    </td>
                                                </>)
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </Modal.Body>
                    </Modal>
                </div>
                {
                    categorytotal.map((category, index) => {
                        if (index === categorytotal.length - 1) {
                            return (
                                <div key={index} className="col-lg-2 col-sm-4 col-6 p-2 " >
                                    <div className=" category-box category-last-box">
                                        <img src={category.mblimg} alt="" style={{ width: '100%' }}
                                        />
                                        <div className='category-show-all' onClick={() => navigate("/shop")}>
                                            <button>Show All</button>
                                        </div>
                                    </div>
                                    <div
                                        className="bg-warning text-dar p-2"
                                        style={{ textAlign: "center" }}
                                        onClick={() => navigate(`/productbycategory/${category.catname}`)}
                                    >
                                        {category.catname}
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div key={index} className="col-lg-2 col-sm-4 col-6 p-2" onClick={() => handleCategory(category)}>
                                    <div className="category-box">
                                        <img src={category.mblimg} alt="" style={{ width: '100%' }}
                                        />
                                        <div className="categoryname-title"></div>
                                    </div>
                                    <div
                                        className="bg-warning text-dar p-2"
                                        style={{ textAlign: "center" }}
                                        onClick={() => navigate(`/shop/productbycategory/${category.catname}`)}
                                    >
                                        {category.catname}
                                    </div>
                                </div>
                            )
                        }
                    }

                    )
                }
            </div>
            <ToastContainer />
        </>
    )
}

export default ShopNow