import React from "react";
import { CiLocationOn } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoIosCall } from "react-icons/io";
import { IoIosMail } from "react-icons/io";

export function AddressHeader() {
  return (
    <>
      <div className="AddressHeader">
        <div className="AddressHeader-left">
          <div className="pe-3">+91 9391445005</div>
          {/* <div>vasantcouriers@info.com</div> */}
        </div>
        <div>
          <div>Want to courier to someone,We are here!!!</div>
        </div>
        <div className=" AddressHeader-right d-none ">
          <div className="pe-3">
            <CiLocationOn />
            Location
          </div>
          <div className="pe-3">
            English
            <RiArrowDropDownLine />
          </div>
          <div>
            USD
            <RiArrowDropDownLine />
          </div>
        </div>
      </div>
    </>
  );
}
