import { useState } from "react";
import { Login } from "../Login/Login";
import { SignIn } from "../Login/Signin";

export function ProfileRegister() {
  const [showRegister, setShowRegister] = useState(true);
  const [showLogin, setShowLogin] = useState(false);

  const openRegister = () => {
    setShowRegister(true);
    setShowLogin(false);
  };

  const openLogin = () => {
    setShowLogin(true);
    setShowRegister(false);
  };
  return (
    <>
      <div className="p100 profile-register">
        <div className="profile-register-btn">
          <div onClick={openLogin} >Login</div>
          <div onClick={openRegister}>Register</div>
        </div>
        <div>{showRegister && !showLogin ? <SignIn /> : <Login />}</div>
      </div>
    </>
  );
}
