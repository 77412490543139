import React from 'react'

function ScrollinText() {
    return (
        <>
            <marquee behavior="scroll" direction="left">
                THE BEST, THE FASTEST AND THE PERFECT COURIER SERVICES IN YOUR CITY. CALL NOW TO ENQUIRY COURIER SERVICE:+91-9391445005
            </marquee>
        </>
    )
}

export default ScrollinText