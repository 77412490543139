import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  clientOrder: [],

  currentOrder:"",
  awb:"",
  ordersLoading: true,
  isordersLoading: true,
};

export const OrderPost = createAsyncThunk(
  "order/OrderPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/productorder/new`;

      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(" Product order not create");
    }
  }
);

export const getOrderbyClId = createAsyncThunk(
  "order/getOrderbyClId",
  async (formData, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/productorder/client/${formData}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Pincode Not Found");
    }
  }
);

const OrderSlice = createSlice({
  name: "Order",
  initialState,
  reducers: {
    clearOrder(state, action) {
      state.clientOrder = [];
      state.currentOrder = "";
      localStorage.setItem(" clientOrder", JSON.stringify(state.clientOrder));
      localStorage.setItem(" currentOrder", JSON.stringify(state.currentOrder));
    },
    clearCurrentOrder(state, action) {
      state.currentOrder = "";
      localStorage.setItem(" currentOrder", JSON.stringify(state.currentOrder));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderbyClId.pending, (state) => {
        state.isordersLoading = true;
      })
      .addCase(getOrderbyClId.fulfilled, (state, action) => {
        state.clientOrder = action.payload.productOrders;
        localStorage.setItem(" clientOrder", JSON.stringify(state.clientOrder));
        state.isordersLoading = false;
      })
      .addCase(getOrderbyClId.rejected, (state) => {
        state.isordersLoading = true;
      })
      .addCase(OrderPost.pending, (state) => {
        state.ordersLoading = true;
      })
      .addCase(OrderPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.clientOrder = [
            action.payload.productOrder,
            ...state.clientOrder,
          ];
          state.ordersLoading = false;
          state.currentOrder = action.payload.productOrder;
          state.awb=action.payload.productOrder.awb;
        }
        localStorage.setItem(" clientOrder", JSON.stringify(state.clientOrder));
        localStorage.setItem(
          " currentOrder",
          JSON.stringify(state.currentOrder)
        );

      })
      .addCase(OrderPost.rejected, (state) => {
        state.ordersLoading = true;
      });
  },
});
export const { clearOrder, clearCurrentOrder } = OrderSlice.actions;
export default OrderSlice.reducer;
