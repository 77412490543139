import { useEffect } from "react";
import { Category } from "../../Category";
import { Footer } from "../../Footer";

import { MainBanner } from "../../MainBanner";
import { OfferGrid } from "../../OfferGrid";
import { OfferProducts } from "../../OfferProducts";
import { RecommendedProduct } from "../../RecommededProducts";
import { TrendingProducts } from "../../TrendingProducts";
import { WebFeatures } from "../../WebFeatures";
import SecondHeader from "../../SecondHeader";

export function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <SecondHeader />
      <MainBanner />
      <Category />
      {/* <TrendingProducts /> */}
      {/* <HotProduct /> */}
      <RecommendedProduct />
      {/* <OfferGrid /> */}
      <OfferProducts />
      {/* <LatestBlog /> */}
      <WebFeatures />

    </>
  );
}
