import { configureStore } from "@reduxjs/toolkit";
import AthenticationReducer from "./athentication/Athentication";
import CartReducer from "./cart/CartSlice";
import CategoryReducer from "./Category/CategorySlice";
import ProductReducer from "./product/ProductSlice"
import BrandReducer from "./brand/BrandSlice";
import WishlistReducer from "./wishlist/WishlistSlice";
import AddressReducer from "./address/Address";
import ProductOrderReducer from "./order/orderSlice"
import CourierSlice from "./courier/CourierSlice"
import CourierpriceReducer from "./courierPrice/courierpriceSlice";
import RequestCourierReducer from "./courierRequest/courierRequestSlice";

export const store = configureStore({
  reducer: {
    Athentication: AthenticationReducer,
    Cart: CartReducer,
    Category:CategoryReducer,
    Product:ProductReducer,
    Brand:BrandReducer,
    Wishlist:WishlistReducer,
    Address:AddressReducer,
    Order:ProductOrderReducer,
    Courier:CourierSlice,
    CourierPrice:CourierpriceReducer,
    CourierRequest:RequestCourierReducer
  },
});
