import React from 'react'

function PastCourierOrders() {
    return (
        <>
            <div className='about-orders'>
                <div className='row'>
                    <div className="order-center col-lg-3 col-md-3 col-6">
                        <div className='order-number'>554</div>
                        <div className='hr-line'> </div>
                        <div className='order-title'>Freights Delivered</div>
                    </div>
                    <div className="order-center col-lg-3 col-md-3 col-6">
                        <div className='order-number'>554</div>
                        <div className='hr-line'> </div>
                        <div className='order-title'>Orders Completed</div>
                    </div>
                    <div className="order-center col-lg-3 col-md-3 col-6">
                        <div className='order-number'>521</div>
                        <div className='hr-line'> </div>
                        <div className='order-title'>Happy Customers</div>
                    </div>
                    <div className="order-center col-lg-3 col-md-3 col-6">
                        <div className='order-number'>254</div>
                        <div className='hr-line'> </div>
                        <div className='order-title'>Commercial Goods</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PastCourierOrders