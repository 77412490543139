import axios from "axios";
import { useEffect, useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { IoMdShuffle } from "react-icons/io";
import {
  Link,
  useHistory,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom"; // Import useHistory

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { addtoCart } from "../../../redux/cart/CartSlice";
import { WishlistPost } from "../../../redux/wishlist/WishlistSlice";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoStar } from "react-icons/io5";
import { IoStarHalf } from "react-icons/io5";
import "react-toastify/dist/ReactToastify.css";
import { SingleItem } from "../../SingleItem";
import SecondHeader from "../../SecondHeader";

export function ProductByCategory() {
  const [products, setProducts] = useState([]);
  const { isAuth, loginData } = useSelector((store) => store.Athentication);
  const { productTotal } = useSelector((store) => store.Product);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let currCategory = [];
  // const category = location.state.data.item;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    console.log(data);
  };

  //   console.log(category);

  useEffect(() => {
    const category = params.slugUrl;
    window.scrollTo(0, 0);
  }, []);

  const addTocartClick = (product) => {
    // notify()
    dispatch(addtoCart(product));
  };

  function notify() {
    toast.success("Product added to cart", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      zIndex: 11000,
    });
  }

  function addToWishlist(product) {
    console.log(product);
    // console.log(loginData.loginData);
    if (isAuth) {
      const formData = {
        clientId: loginData._id,
        category: product.category,
        categoryId: product.categoryId,
        productid: product._id,
        productName: product.name,
        price: product.packsize[0].price,
        image: product.mblimg,
        brand: product.brand,
      };
      console.log(formData, "formData");
      dispatch(WishlistPost(formData));
      alert(`${product.name} has been added to wishlist`);
    }
  }

  return (
    <>
      <SecondHeader />
      <div>
        {/* <div className="category-header">{categorySlugUrl}</div> */}
        <div className="p100 category-products">
          <div className="category-filters">
            <div>SHOWING ALL RESULTS</div>
          </div>
          <div className="row">
            {productTotal.map((data, index) =>
              data.category === params.slugUrl ? (
                <>                  <SingleItem key={index} data={data} />

                </>
              ) : <>
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
