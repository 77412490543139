import { FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io";
import { CiLinkedin } from "react-icons/ci";
import { FaPinterestP } from "react-icons/fa";
import { Link } from "react-router-dom";

export function Footer() {
  return (
    <>
      <div className="p100 footer">
        <div className="row">
          <div className="col-lg-4 mt-3">
            <h2>
              <Link to="/" className="tocart">Vasant Couriers</Link>
            </h2>
            <div>
              <IoLocationSharp />
              12-2-826/A/46,Lic colony, Mehdipatnam, Hyderabad -500028
            </div>
            <div>
              <IoMail /> vasantcouriers@info.com
            </div>
            <div>
              <FaPhone /> +91 9391445005
            </div>
            <div>
              <FaFacebook /> <FaXTwitter /> <IoLogoInstagram /> <CiLinkedin />{" "}
              <FaPinterestP />
            </div>
          </div>
          <div className="col-lg-8  mt-3 footer-middlepanel">
            <div>
              <h4>Categories</h4>
              <div>Nonveg Pickles</div>
              <div>Veg Pickles</div>
              <div>Powder Masala</div>
              <div>Sweets</div>
            </div>
            <div>
              <h4>Infomation</h4>
              <div>About us</div>
              <div>Contact us</div>
              <div>Terms & Conditions</div>
              <div>Returns & Exchanges</div>
              <div>Shipping & Delivery</div>
              <div>Privacy Policy</div>
            </div>
            <div>
              <h4>Useful links</h4>
              <div>Latest News</div>
              <div>My Account</div>
              <div>FAQs</div>
              <div>FAQs 2</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
