import { FaRegHeart } from "react-icons/fa";
import { IoMdShuffle } from "react-icons/io";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addtoCart } from "../redux/cart/CartSlice";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoStar } from "react-icons/io5";
import { IoStarHalf } from "react-icons/io5";
import "react-toastify/dist/ReactToastify.css";
import { WishlistPost } from "../redux/wishlist/WishlistSlice";
import "react-toastify/dist/ReactToastify.css";
import { SingleItem } from "./SingleItem";

export function RecommendedProduct() {
  const [products, setProducts] = useState([]);
  const { recommendedTotal } = useSelector((store) => store.Product);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    console.log(data);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = "http://localhost:4036/api/v1/product/all";
        const fetchProducts = await axios.get(url);
        if (fetchProducts.data.success === true) {
          setProducts(fetchProducts.data.Products);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  // console.log(recommendedTotal);

  const addTocartClick = (product) => {
    // notify()
    dispatch(addtoCart(product));
    // console.log(product);
  };

  function notify() {
    toast.success("Product added to cart", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      zIndex: 11000,
    });
  }

  return (
    <>
      <div className="p100 trending">
        <div className="trending-heading">
          <div className="title">Recommended Products</div>
          <div className="sm-hr">
            <hr />
          </div>
        </div>
        <div className="trending-subheading">
          Top Recommended products in this week
        </div>
        <div className="row">
          {recommendedTotal.map((data, index) =>{
            return <SingleItem data={data} key={index}/>
          }

)}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
